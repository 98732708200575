export const EVENT_OWNER  = 'EVENT_OWNER';
export const LEAD_INVESTIGATOR = 'LEAD_INVESTIGATOR'

export const SeverityOptions = [
	{ label: 'Level 1', value: 1 },
	{ label: 'Level 2', value: 2 },
	{ label: 'Level 3', value: 3 },
	{ label: 'Level 4', value: 4 },
	{ label: 'Level 5', value: 5 }
]
export const EVENT_TYPE_CHOICES = [
	{ value: 'Incident', label: 'Incident' },
	{ value: 'Near Miss(Potential incident)', label: 'Near Miss(Potential incident)' }
  ];
  
export const EVENT_GROUP_CHOICES = [
	{ value: 'Injury or Illness', label: 'Injury or Illness' },
	{ value: 'Property Damage', label: 'Property Damage' },
	{ value: 'Loss or Failure', label: 'Loss or Failure' },
	{ value: 'Environmental', label: 'Environmental' },
	{ value: 'Spill / Release', label: 'Spill / Release' },
	{ value: 'Non-Compliance or Breach of Licence', label: 'Non-Compliance or Breach of Licence' },
	{ value: 'Community', label: 'Community' },
	{ value: 'Reputational', label: 'Reputational' },
	{ value: 'Business Interruption', label: 'Business Interruption' },
	{ value: 'Quality (no EHS impact)', label: 'Quality (no EHS impact)' }
];


export const EVENT_STAGE_CHOICES = [
	{ value: 0, label: 'Reported' },
	{ value: 1, label: 'Live' },
	{ value: 2, label: 'Rejected' },
	{ value: 3, label: 'Under Review' },
	{ value: 4, label: 'Closed' }
];