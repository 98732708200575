import * as React from 'react';
import { BEDrawer } from '../../../Components/BEDrawer';
import FileUpload from '../../../Components/BEFileUpload/FileUpload';
import { MyMetric, MyMetricData } from '../../../Redux/Types/dataManagerTypes';
import { BEButton } from '../../../Components/BEFormItems/BEButton';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import { LableRequired } from '../../../Components/BEFormItems/LableRequired';
import { Form } from 'antd';
import { BEInput } from '../../../Components/BEFormItems/BEInput';
import { BETextArea } from '../../../Components/BEFormItems/BETextArea';
import './styles.scss';
import BEMultipleSelect from '../../../Components/BEMultipleSelect';
import { rotateArray, AllMonths } from '../../../Config/Functions/UsefullFunctions';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import { getDateBYMonthofEntry } from '../../../Features/DataManager/Components/DataEntry/Drawers/NewDataEntry';
import { BEMessage } from '../../../Components/BEMessage';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { fetchDataFromEvidence, patchMetricsStatus, postMetricsData } from '../../../Features/DataManager/Actions';
import BEConfirmModal from '../../../Components/BEConfirmModal';
import { checkCeleryTaskStatusPeriodically, getCeleryTaskResult } from '../../../views/Actions/HomeActions';
import { ocrBcodes } from '../../../Config/Data/genralData';

interface IDataEntryDrawerProps {
	// open: boolean;
	metric: MyMetric | null;
	setOpen: Function;
	myMetricData?: MyMetricData | null;
}

export function DataEntryDrawer(props: IDataEntryDrawerProps) {
	const [form] = Form.useForm();
	const dispatch = useAppDispatch();
	const [file, setFile] = React.useState(null);
	const yearEnd = useTypedSelector(state => state.companies.yearEnd);
	const companyDetails = useTypedSelector(state => state.companies.companyDetails);
	const [loading, setLoading] = React.useState(false);
	const currentBusinessUnit = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
	const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
	const metricsStatus = useTypedSelector(state => state.dataManager.metrics.metricStatus);
	const myMetricData = useTypedSelector(state => state.onBoarding.metrics.myMetricsData);
	const [showOCRModal, setShowOCRModal] = React.useState(false);
    const [ocrLoading, setOcrLoading] = React.useState(false);
	const role = useTypedSelector(state => state.user.userRole);
	// const [isAddAnother, setIsAddAnother] = React.useState(false);


	React.useEffect(() => {
		if (props.myMetricData) {
			form.setFieldsValue({
				month: AllMonths[Number(props.myMetricData.date_of_entry.split('-')[1]) - 1],
				value: props.myMetricData.value,
				cost: props.myMetricData.cost,
				description: props.myMetricData.description
			})
		} else {
			form.resetFields();
		}
	}, [props.myMetricData, props.metric])

	const changeMetricStatusToPending = async (values: any) => {
		let currentRowYear = currentYear
		let currentRowMonth = AllMonths.indexOf(values.month) + 1;
		let currentMetricStatus = metricsStatus?.data.filter((item: any) => item.my_metric === props.metric?.id && item.year === currentRowYear && item.month === currentRowMonth && item.business_unit === currentBusinessUnit)[0];
		if (currentMetricStatus) {
			await dispatch(patchMetricsStatus(currentMetricStatus?.id, {
				status: 1
			}));
		}
		setLoading(false);
		form.resetFields();
		setFile(null);
	}

	const handleSave = async (values: any) => {
		setLoading(true);
		// const limitCrossed: boolean = (values.value > metric.max_limit || values.value < metric.min_limit) && metric.limit_status
		let dateOfEntry = getDateBYMonthofEntry(values.month, currentYear, yearEnd);
		if (props.metric?.annual) {
			const isRepeatedEntry = myMetricData.data?.find((item) => item.my_metric === props.metric?.id &&
				item.business_unit === (currentBusinessUnit === 0 ? values.business_unit : currentBusinessUnit) &&
				Number(item.date_of_entry.split('-')[1]) === AllMonths.indexOf(values.month) + 1)
			if (isRepeatedEntry) {
				BEMessage.error('Data already entered for this business unit in the month of ' + values.month);
				setLoading(false);
				return;
			}
		}
		let data = {
			...values,
			my_metric: props.metric?.id,
			business_unit: currentBusinessUnit === 0 ? values.business_unit : currentBusinessUnit,
			date_of_entry: dateOfEntry,
			year: currentYear,
			status: role === 'CONTRIBUTOR' ? 1 : 2,
			bcode: props.metric?.bcode,
		}
		delete data.month
		delete data.label
		if (!data.source) delete data.source;
		if (!data.description) delete data.description;
		if (file) data.evidence = file;
		if (!data.cost) delete data.cost;
		console.log(data)
		await dispatch(postMetricsData(data)).then(async (res: any) => {
			changeMetricStatusToPending(values);
			setLoading(false);
			form.resetFields();
			setFile(null);
			// props.setOpen(isAddAnother);
		})
		setLoading(false);
		props.setOpen(null);
	}

	const limitedDecimalCheckValidator = (rule: any, value: any) => {
		if (value && value.toString().split(".")[1]?.length > 2) {
			form.setFields([{
				name: 'cost',
				errors: ['Cost can have only 2 decimal places']
			}])
			return Promise.reject('Cost can have only 2 decimal places');
		}
		else return Promise.resolve();
	}

	return (
		<BEDrawer
			open={props.metric !== null}
			setOpen={() => props.setOpen(null)}
			width='100%'
			height={window.innerHeight - 100}
			heading={props.metric?.title}
			isMobile={true}
			footer={
				<>
					{/* <BEButton
				 onClick={()=> {
					form.submit()
				}} style={{color:PrimaryTheme.primaryGreen, borderColor:PrimaryTheme.primaryGreen}} >Save & add more</BEButton> */}
					{!props.myMetricData && <BEButton loading={loading} className='primary' onClick={() => {
						form.submit()
					}}>Add entry</BEButton>}
				</>
			}
		>
			<>
				<FileUpload
					required={false}
					text='Upload Evidence'
					hint='File can be in any format up to 5MB in size'
					fileSize={5000000}
					fileType='any'
					handleFileChange={(file: any) => {
						setFile(file);
						if (file && ocrBcodes.includes(props.metric?.bcode as string)) setShowOCRModal(true);
					}}
					loading={ocrLoading}
					height='5rem'
					width='95%'
				/>
				{/* <div 
				style={{
					padding:'1rem 0',
				 	display:'flex',
					gap:'1rem',
					borderBottom:`1px solid ${PrimaryTheme.primaryGreyLight}`
				}}>
				<BEButton style={{color:PrimaryTheme.primaryGreenDark, borderColor:PrimaryTheme.primaryGreenDark, width:'50%'}} >
					Choose file
				</BEButton>
				<BEButton style={{color:PrimaryTheme.primaryGreenDark, borderColor:PrimaryTheme.primaryGreenDark, width:'50%'}}>
					Capture image
				</BEButton>
			</div> */}

				<Form
					style={{ display: 'grid', marginTop: '1rem' }}
					form={form}
					onFinish={handleSave}
				>
					<LableRequired>
						Month
					</LableRequired>
					<BEMultipleSelect isDataStrArray single name='month' data={rotateArray(AllMonths, yearEnd, true)} rules={[
						{
							required: true,
							message: 'Please select month',
						},
					]}
					/>
					<LableRequired>
						Value ({props.metric?.unit})
					</LableRequired>
					<Form.Item name='value' rules={[{
						required: true, message: 'Please enter value'
					}
					]}>
						<BEInput type='number' />
					</Form.Item>
					<p>
						Cost ({companyDetails?.data?.currency})
					</p>
					<Form.Item name='cost' rules={[
						{ message: 'Please select source', required: false },
						{ validator: limitedDecimalCheckValidator }
					]}>
						<BEInput />
					</Form.Item>
					<p>
						Description
					</p>
					<Form.Item name='description'>
						<BETextArea rows={4} placeholder='Description of the entry' form={form} />
					</Form.Item>
				</Form>
			</>
			<BEConfirmModal
				type="success"
				title='Fetch data from pdf'
				visible={showOCRModal}
				setVisible={setShowOCRModal}
				onConfirm={async () => {
					setOcrLoading(true);
					await dispatch(fetchDataFromEvidence(file, props?.metric?.bcode as string)).then(async (taskId) => {
						console.log(taskId);
						if (taskId) {
							await dispatch(checkCeleryTaskStatusPeriodically([taskId], 15000, async () => {
								await dispatch(getCeleryTaskResult(taskId)).then((val) => {
									if (val) {
										form.setFieldsValue({
											value: val,
										});
									}
									setOcrLoading(false);
								});
							}));
						}
					});
				}}
				message={"Do you want to fetch data from the pdf?"}
			/>
		</BEDrawer>
	);
}
