import * as React from 'react';
import { Outlet } from 'react-router-dom';

export interface IEventManagerLayoutProps {
}

export function EventManagerLayout(props: IEventManagerLayoutProps) {
    return (
        <Outlet />
    );
}
