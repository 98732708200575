import * as React from 'react';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { EventDetails } from './Details';
import { EventInvestigation } from './Investigation';
import { EventActions } from './EventActions';
import { FormInstance } from 'antd';

export interface IInvestigationReportRightSectionProps {
    form: FormInstance<any>
    showInvestigationForm: boolean;
}

export function InvestigationReportRightSection(props: IInvestigationReportRightSectionProps) {
    const currentInvestigationReportSection = useTypedSelector(state => state.eventManager.currentInvestigationReportSection);
    return (
        <div>
            {
                currentInvestigationReportSection === 'Details' ?
                    <EventDetails />
                    : currentInvestigationReportSection === 'Investigation' ?
                        <div>
                            <EventInvestigation form={props.form} showInvestigationForm={props.showInvestigationForm} />
                        </div>
                        : currentInvestigationReportSection === 'Actions' ?
                            <div>
                                <EventActions />
                            </div>
                            : null
            }
        </div>
    );
}
