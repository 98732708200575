import * as React from 'react';
import { BEDrawer } from '../../../../../../Components/BEDrawer';
import { Form } from 'antd';
import FileUpload from '../../../../../../Components/BEFileUpload/FileUpload';
import { LableRequired } from '../../../../../../Components/BEFormItems/LableRequired';
import { BETextArea } from '../../../../../../Components/BEFormItems/BETextArea';
import { BEButton } from '../../../../../../Components/BEFormItems/BEButton';

interface IAddInvestigationReportDrawerProps {
	open: boolean;
	setOpen: Function;
}

export function AddInvestigationReportDrawer(props: IAddInvestigationReportDrawerProps) {
	const [form] = Form.useForm();
	const [file, setFile] = React.useState(null);
	return (
		<BEDrawer
			heading="Action"
			open={props.open}
			setOpen={props.setOpen}
			footer={
				<>
					<BEButton size='large' className='primary' onClick={() => form.submit()}>
						Mark as completed
					</BEButton>
					<BEButton size='large' className='secondary' onClick={() => props.setOpen(false)}>
						Cancel
					</BEButton>
				</>
			}
		>
			<Form form={form}>
				<LableRequired>
					Investigation report
				</LableRequired>
				<Form.Item name={'investigationReport'}>
					<FileUpload
						height='75px'
						width='315px'
						fileType={'pdf'}
						text='Upload Investigation Report'
						hint='PDF or .ZIP, Max 3MB'
						handleFileChange={setFile}
						refreshUpload={props.open}
					/>
				</Form.Item>
				<LableRequired>
					Description
				</LableRequired>
				<Form.Item name={'description'}>
					<BETextArea
						form={form}
						placeholder='Write here'
						rows={4}
					/>
				</Form.Item>
			</Form>
		</BEDrawer>
	);
}
