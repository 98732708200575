import * as React from 'react';
import { BEDrawer } from '../../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../../Components/BEFormItems/BEButton';
import { LableRequired } from '../../../../../../Components/BEFormItems/LableRequired';
import FileUpload from '../../../../../../Components/BEFileUpload/FileUpload';
import { Form } from 'antd';
import { BETextArea } from '../../../../../../Components/BEFormItems/BETextArea';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { EventAction } from '../../../../../../Redux/Types/eventManagerTypes';
import { deleteEventEvidence, patchEventAction, postEventEvidence } from '../../../Actions';
import { BEMessage } from '../../../../../../Components/BEMessage';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { BEEvidenceList } from '../../../../../../Components/BEEventManagement/BEEvidenceList';
import { EVENT_OWNER } from '../../../../../../data/eventManager';

interface IAddActionEvidenceDrawerProps {
	open: boolean;
	setOpen: Function;
	action: EventAction | undefined;
}

export function AddActionEvidenceDrawer(props: IAddActionEvidenceDrawerProps) {
	const dispatch = useAppDispatch();
	const [form] = Form.useForm();
	const [file, setFile] = React.useState<any>(null);
	const [loading, setLoading] = React.useState(false);
	const eventEvidences = useTypedSelector(state => state.eventManager.eventEvidences);
	const allEvents = useTypedSelector(state => state.eventManager.allEvents);
	const [currentEvidences, setCurrentEvidences] = React.useState<any>([]);

	React.useEffect(() => {
		if (eventEvidences.data && [0, 1, 2].includes(props.action?.stage as number)) {
			const filteredEvidences = eventEvidences.data.filter((evidence: any) => evidence.action === props.action?.id && evidence.event === props.action?.event);
			setCurrentEvidences(filteredEvidences);
		}

	}, [eventEvidences, props.action]);

	React.useEffect(() => {
		if (props.action?.stage === 1) {
			form.setFieldsValue({ description: props.action?.description });
		} else {
			form.resetFields();
		}
	}, [props.open]);

	const handleMarkAsCompleted = async (values: any) => {
		if (!file) {
			BEMessage.error('Please select a file to upload');
			return;
		}
		if (props.action) {
			setLoading(true);
			await dispatch(postEventEvidence({
				file: file,
				event: props.action?.event,
				action: props.action?.id,
			})).then(() => {
				if (props.action) {
					dispatch(patchEventAction(props.action?.id, {
						stage: 1,
						description: values.description
					}));
				}
			})
		}
		setLoading(false);
		props.setOpen(false);
	}

	const handleApprove = async (values: any) => {
		if (props.action) {
			setLoading(true);
			await dispatch(patchEventAction(props.action?.id, {
				stage: 2,
			}));
		}
		setLoading(false);
		props.setOpen(false);
	}

	const handleReject = async () => {
		if (props.action) {
			setLoading(true);
			await dispatch(patchEventAction(props.action?.id, {
				stage: 3,
				description: null
			})).then(async () => {
				await dispatch(deleteEventEvidence(currentEvidences[0!]?.id));
			});
		}
		setLoading(false);
		props.setOpen(false);
	}

	return (
		<BEDrawer
			heading='Action'
			open={props.open}
			setOpen={props.setOpen}
			width='30%'
			footer={
				<>
					{(props.action?.stage === 0 || props.action?.stage === 3) ?
						<BEButton loading={loading} size='large' className='primary' onClick={() => { form.submit() }}>
							Mark as completed
						</BEButton> :
						(props.action?.stage === 1 && allEvents.data?.find((event) => event.id === props.action?.event)?.event_user_role === EVENT_OWNER) ?
							<>
								<BEButton loading={loading} size='large' className='primary' onClick={() => { form.submit() }}>
									Approve
								</BEButton>
								<BEButton loading={loading} size='large' className='primary-red' onClick={() => { handleReject() }}>
									Reject
								</BEButton>
							</> : null
					}
					<BEButton size='large' className='secondary' onClick={() => props.setOpen(false)}>
						Cancel
					</BEButton>
				</>}
		>
			<Form form={form} onFinish={(props.action?.stage === 0 || props.action?.stage === 3)? handleMarkAsCompleted : handleApprove}>
				<LableRequired>
					Evidence
				</LableRequired>
				{currentEvidences.length > 0 ? <BEEvidenceList
					evidenceList={currentEvidences.map((evidence: any) => evidence.file)}
				/> : <FileUpload
					width='95%'
					height='55px'
					handleFileChange={(file: any) => setFile(file)}
					text='Upload Evidence'
					hint='PDF or Image, Max 3MB'
					refreshUpload={props.open}
					fileType={["application/pdf", "image/png", "image/jpeg", "image/svg"]}
					fileSize={3000000}
				/>}
				<br />
				<LableRequired>
					Description
				</LableRequired>
				<Form.Item name={'description'}>
					<BETextArea
						disabled={props.action?.stage === 1}
						form={form}
						placeholder='Write here'
						rows={8}
						count={{
							show: true,
							max: 500,
						}}
						maxLength={500}
					/>
				</Form.Item>
			</Form>
		</BEDrawer>
	);
}
