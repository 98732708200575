import * as React from 'react';
import { BEDrawer } from '../../../../../../Components/BEDrawer';
import { LableRequired } from '../../../../../../Components/BEFormItems/LableRequired';
import { Form } from 'antd';
import { BETextArea } from '../../../../../../Components/BEFormItems/BETextArea';
import { BEButton } from '../../../../../../Components/BEFormItems/BEButton';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { patchEvent } from '../../../Actions';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';

interface IRejectInvestigationReportProps {
	open: boolean;
	setOpen: Function;
}

export function RejectInvestigationReport(props: IRejectInvestigationReportProps) {
	const [form] = Form.useForm();
	const dispatch = useAppDispatch();
	const currentEvent = useTypedSelector(state => state.eventManager.currentEvent);
	const [loading, setLoading] = React.useState(false);
	//  onClick={async () => {
	// 												if (currentEvent) {
	// 													setLoading(true);
	// 													await dispatch(patchEvent(currentEvent?.id, {
	// 														stage: 6,
	// 													}));
	// 													setLoading(false);
	// 												}
	// 											}}

	const handleRejection = async(values: any) => {
		console.log(values);
		if (currentEvent) {
			setLoading(true);
			await dispatch(patchEvent(currentEvent?.id, {
				stage: 6,
				rejection_reason: values.comments,
			}));
			setLoading(false);
		}
		props.setOpen(false);
	}

	return (
		<BEDrawer
			heading="Reject report"
			open={props.open}
			setOpen={props.setOpen}
			footer={
				<>
					<BEButton loading={loading} className='primary' onClick={() => form.submit()}>
						Reject
					</BEButton>
					<BEButton className='secondary-green' onClick={() => props.setOpen(false)}>
						Cancel
					</BEButton>
				</>
			}
		>
			<Form form={form} onFinish={handleRejection}>
				<LableRequired>
					Comments
				</LableRequired>
				<Form.Item name={'comments'} rules={[{ required: true, message: 'Please enter comments' }]}>
					<BETextArea
						name="comments"
						form={form}
						placeholder="Write here"
						rows={5}
						maxLength={200}
						count={{
							show: true,
							max: 200,
						}}
					/>
				</Form.Item>
			</Form>

		</BEDrawer>
	);
}
