import React, { useState } from 'react'
import { Modal } from 'antd';
import WarningIcon from '../BEIcons/WarningIcon';
import { PrimaryTheme } from '../../Config/Theme/theames';
import { BEButton } from '../BEFormItems/BEButton';
import CheckFilledIcon from '../BEIcons/CheckFilledIcon';

interface BEConfirmModalProps {
  allowAuditorAccess?: boolean;
  title: string,
  message: string,
  visible: boolean,
  setVisible: Function,
  onConfirm: Function
  type?: 'warning' | 'success' | 'error';
  confirmButtonText?: string;
  cancelButtonText?: string;
  hideButtons?: boolean;
}

const BEConfirmModal = (props: BEConfirmModalProps) => {
  const [loading, setLoading] = useState(false)

  const handleOk = async () => {
    setLoading(true)
    const res = await props.onConfirm()
    setLoading(false)
    handleCancel()
  }

  const handleCancel = () => {
    props.setVisible(false)
  }
  return (
    <Modal
      title={[
        <div style={{ height: "2rem" }} className='be-confirmation-modal'>
          {
            (props.type && props.type === 'success') ?
              <CheckFilledIcon inheritSize fill={PrimaryTheme.primaryGreen} /> :
              <WarningIcon inheritSize fill={
                (props.type && props.type === 'warning') ? PrimaryTheme.amber : PrimaryTheme.primaryRedError
              } />

          }
        </div>,
        <div style={{ fontSize: "18px", textAlign: "center" }}>{props.title}</div>,
      ]}
      confirmLoading={loading}
      width={"350px"}
      open={props.visible}
      footer={
        props.hideButtons ? null :
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1.5rem' }}>
          <BEButton
            allowAuditorClick={props.allowAuditorAccess}
            onClick={handleOk}
            className={(props?.type === "error" || !props.type) ? 'primary-red' : 'primary'}
            loading={loading}
          >
            {props.confirmButtonText || "Confirm"}
          </BEButton>
          <BEButton
            allowAuditorClick={props.allowAuditorAccess}
            onClick={handleCancel}>
            {props.cancelButtonText || "Cancel"}
          </BEButton>
        </div>
      }
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div style={{ color: PrimaryTheme.primaryGray, textAlign: "center" }}>
        {props.message}
      </div>
    </Modal>
  );
}

export default BEConfirmModal