import * as React from 'react';
import { Col, Drawer, DrawerProps, Row } from 'antd';
import './style.scss';
import CancleIcon from '../../BEIcons/CancleIcon';

export interface IBEDrawerType1Props extends DrawerProps {
    open: boolean;
    setOpen: Function;
    heading?: string;
    subHeading?: string;
    headerRight?: React.ReactNode;
    rightBody?: React.ReactNode;
    leftBody?: React.ReactNode;
    leftFooter?: React.ReactNode;
    leftBodyFooter?: React.ReactNode;
    rightFooter?: React.ReactNode;
    footerBackgroundColour?: string;
    footerBorderTop?: string;

}

export function BEDrawerType1(props: IBEDrawerType1Props) {

    return (
        <Drawer
            width="100vw"
            placement="right"
            onClose={() => { props.setOpen(false) }}
            open={props.open}
            closable={false}
        >
            <Row className='header'>
                <div>
                    {props.heading + " "}
                    {props.subHeading &&
                        <span style={{ fontWeight: "200" }}>
                            | {props.subHeading}
                        </span>
                    }
                </div>
                <div style={{ flexGrow: 1 }} />
                <div className='header-right'>
                    {props.headerRight}
                </div>
                <div className='cancel' onClick={() => props.setOpen(false)}>
                    <CancleIcon fill={"white"} inheritSize />
                </div>
            </Row>
            <Row className='drawer-body'>
                <Col className='left-body'>
                    {props.leftBody}
                    <div className='footer-left-body'>
                        {props.leftBodyFooter}
                    </div>
                </Col>
                <Col className='right-body'>
                    <div className='right-scroll'>
                        {props.rightBody}
                    </div>
                    <div className='footer' style={{ backgroundColor: props.footerBackgroundColour, borderTop: props.footerBorderTop }}>
                        <div className='footer-left'>
                            {props.leftFooter}
                        </div>
                        <div className='footer-right'>
                            {props.rightFooter}
                        </div>
                    </div>
                </Col>
            </Row>

        </Drawer>
    );
}
