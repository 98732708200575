import { configureStore, createAction } from "@reduxjs/toolkit";
import userReducer from "./UserReducer";
import companiesReducer from "./CompaniesReducer";
import onBoadingReducer from "./OnboardingReducer";
import entityReducer from "./EntityReducer";
import userManagementReducer from "./UserManagementReducer";
import commonReducer from "./CommonReducer";
import settingReducer from './SettingsReducer'
import DataManagerReducer from "./DataManagerReducer";
import matirialityReducer from "./MatirialityReducer";
import supplierReducer from "./SupplierReducer";
import reportingReducer from "./ReportingReducer";
import EmisionsReducer from "./EmmisionsReducer";
import VaultReducer from "./VaultReducer";
import targetReducer from "./TargetsReducer";
import EventManagerReducer from "./EventManagerReducer";


const store = configureStore({
  reducer: {
    user: userReducer,
    companies: companiesReducer,
    onBoarding: onBoadingReducer,
    entity: entityReducer,
    userMgmt: userManagementReducer,
    targets: targetReducer,
    common: commonReducer,
    settings: settingReducer,
    dataManager: DataManagerReducer,
    matiriality: matirialityReducer,
    supplier: supplierReducer,
    reporting: reportingReducer,
    emissions: EmisionsReducer,
    vault:VaultReducer,
    eventManager: EventManagerReducer,
  },
});

//to clear the store

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
