import Administration from "../../assets/images/DepartmentImages/Administration.png";
import Finance from "../../assets/images/DepartmentImages/Finance.png";
import HumanResources from "../../assets/images/DepartmentImages/HumanResources.png";
import InformationTechnology from "../../assets/images/DepartmentImages/InformationTechnology.png";
import Legal from "../../assets/images/DepartmentImages/Legal.png";
import Operations_Projects from "../../assets/images/DepartmentImages/Operation_Projects.png";
import Procurement from "../../assets/images/DepartmentImages/Procurement.png";
import ESG_EHS from "../../assets/images/DepartmentImages/ESG_EHS.png";
import Others from "../../assets/images/DepartmentImages/Other.png";

export const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const UserDepartments = [
    {name: 'Administration', image: Administration},
    {name: 'Finance', image: Finance},
    {name: 'Human Resources', image: HumanResources},
    {name: 'Information Technology', image: InformationTechnology},
    {name: 'Legal', image: Legal},
    {name: 'Operations / Projects', image: Operations_Projects},
    {name: 'Procurement', image: Procurement},
    {name: 'ESG / EHS', image: ESG_EHS},
    {name: 'Others', image: Others}
];

export const Features = ['Dashboard', 'DataManager', 'EntityManager', 'Reporting', 'Emissions', 'Targets', 'Surveys', 'Settings', 'Configuration']
export const Roles= ['SUPER_ADMIN', 'ADMIN', 'DEPARTMENT_HEAD', 'CONTRIBUTOR', 'ESG_CONSULTANT', 'AUDITOR']


export const ocrBcodes = ["BE008.19", "BE008.14", "BE008.18", "BE015.291", "BE015.246", "BE015.297", "BE015.301", "BE014.6", "BE033.1", "BE008.21"];

export const targetYears = [2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010,
    2011, 2012, 2013,2014, 2015, 2016, 2017, 2018, 2019, 2020,
    2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
    2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040,
    2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050
];

export const options = [
    { value: 'A', label: 'absolute increase' },
    { value: 'B', label: 'absolute decrease' },
    { value: 'C', label: '% increase' },
    { value: 'D', label: '% decrease' }
];