import * as React from 'react';
import { EventInfo } from '../../../EventInfo';

export interface IEventDetailsProps {
}

export function EventDetails (props: IEventDetailsProps) {
  return (
    <div style={{padding:'1rem'}}>
      <EventInfo/>  
    </div>
  );
}
