import React from 'react';
import { Modal } from 'antd';
import './style.scss';
import { BEButton } from '../../../../../../Components/BEFormItems/BEButton';
import { EventInfo } from '../../EventInfo';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { patchEvent } from '../../../Actions';
import { BEMessage } from '../../../../../../Components/BEMessage';
import { AddEventDrawer } from '../AddEventDrawer';
import { setCurrentEventManagementTab } from '../../../../../../Redux/EventManagerReducer';

export interface IEventInfoModalProps {
    open: boolean;
    onCancel: Function;
}

export function EventInfoModal(props: IEventInfoModalProps) {
    const dispatch = useAppDispatch();
    const currentEvent = useTypedSelector(state => state.eventManager.currentEvent);
    const [loading, setLoading] = React.useState(false);
    const [addEventDrawer, setAddEventDrawer] = React.useState(false);

    const handleValidate = async () => {
        if (currentEvent) {
            await dispatch(patchEvent(currentEvent?.id, { stage: 1 })).then(() => {
                dispatch(setCurrentEventManagementTab('My tasks'));
            });
        }
        props.onCancel(false);
    }

    return (
        <>
            <Modal
                title={currentEvent?.title}
                width={"75%"}
                open={props.open}
                onCancel={() => props.onCancel()}
                footer={
                    <div className='event-info-modal-footer'>
                        <BEButton
                            className="primary"
                            onClick={handleValidate}
                        >
                            Validate
                        </BEButton>
                        <BEButton
                            className="secondary-green"
                            onClick={() => setAddEventDrawer(true)}
                        >
                            Edit details
                        </BEButton>
                        {/* <BEButton
                        loading={loading}
                        onClick={() => {
                            if (!currentEvent) return;
                            setLoading(true);
                            dispatch(patchEvent(currentEvent?.id, { stage: 2 })).then((status) => {
                                if (status) {
                                    BEMessage.success('Event validated successfully');
                                    props.onCancel(false);
                                } else {
                                    BEMessage.error('Failed to validate event');
                                }
                                setLoading(false);
                            }).catch(() => {
                                BEMessage.error('Failed to validate event');
                                setLoading(false);
                            });
                        }}
                    >
                        Reject
                    </BEButton> */}
                        <BEButton
                            onClick={() => props.onCancel(false)}
                        >
                            Cancel
                        </BEButton>
                    </div>
                }
            >
                <EventInfo />
            </Modal>
            <AddEventDrawer
                open={addEventDrawer}
                setOpen={setAddEventDrawer}
            />
        </>
    );
}

