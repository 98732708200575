import { Form, FormInstance } from 'antd';
import * as React from 'react';
import { InvestigationDetails } from '../../../../InvestigationDetails';
import { BEButton } from '../../../../../../../../Components/BEFormItems/BEButton';
import { useAppDispatch } from '../../../../../../../../Config/Hooks/useAppDispatch';
import { patchEvent, postEventEvidence } from '../../../../../Actions';
import { useTypedSelector } from '../../../../../../../../Config/Hooks/useTypedSelector';
import { BEMessage } from '../../../../../../../../Components/BEMessage';
import { setCurrentInvestigationReportSection } from '../../../../../../../../Redux/EventManagerReducer';
import { GlobalLoader } from '../../../../../../../../Components/GlobalLoader';
import { BEInfoBox } from '../../../../../../../../Components/BEInfoBox';
import BulbInfoIcon from '../../../../../../../../Components/BEIcons/BulbInfoIcon';

interface IInvestigationFormProps {
	form: FormInstance<any>
}

export function InvestigationForm(props: IInvestigationFormProps) {
	const [file, setFile] = React.useState();
	const dispatch = useAppDispatch();
	const currentEvent = useTypedSelector(state => state.eventManager.currentEvent);
	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		props.form.resetFields();
	}, [currentEvent]);

	const handleFinish = (values: any) => {
		if (!file) {
			BEMessage.error('Please select a file to upload');
			return;
		}
		if (currentEvent) {
			setLoading(true);
			const data = {
				root_cause: values.rootCause,
				contributing_factors: values.contributoryCause,
				event: currentEvent?.id,
				investigation_report: file,
				...(currentEvent?.stage === 6 && { stage: 3 })
			}
			dispatch(patchEvent(currentEvent?.id, data)).then((status) => {
				setLoading(false);
				if (status) {
					BEMessage.success('Investigation report added successfully');
					dispatch(setCurrentInvestigationReportSection('Actions'));
				} else {
					BEMessage.error('Failed to add investigation report');
				}
			}).catch(() => {
				BEMessage.error('Failed to add investigation report');
				setLoading(false);
			})
		}
	}

	return (
		<>
			{loading ? <GlobalLoader />
				:
				<>
					<BEInfoBox
						description='Upload the investigation documents and actions to submit for review.'
						icon={<BulbInfoIcon/>}
						backgroundColor='#FFFAEC'
					/>
					<InvestigationDetails
						form={props.form}
						onFinish={handleFinish}
						setFile={setFile}
					/>
				</>
			}
			{/* <div style={{ display: 'flex', gap: '1rem' }}>
				<BEButton size='large' className='primary' onClick={() => form.submit()}>
					Save
				</BEButton>
				<BEButton size='large' className='secondary-green'>
					Save & add action
				</BEButton>
			</div> */}
		</>
	);
}
