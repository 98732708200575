import { EventManagerState } from "../Types/eventManagerTypes";
import {
    Reducer,
    createSlice,
  } from "@reduxjs/toolkit";


const initialState: EventManagerState = {
    allEvents: {
        status: 'idle',
        data: null
    },
    eventUsers: {
        status: 'idle',
        data: null
    },
    currentEvent: null,
    eventActions: {
        status: 'idle',
        data: null
    },
    allActions: {
        status: 'idle',
        data: null
    },
    eventEvidences: {
        status: 'idle',
        data: null
    },
    currentEventManagementTab: 'My tasks',
    currentInvestigationReportSection: 'Details',
};

export const eventManagerSlice = createSlice({
    name: "eventManager",
    initialState,
    reducers: {
        setAllEvents: (state, action) => {
            state.allEvents = action.payload;
        },
        setEventUsers: (state, action) => {
            state.eventUsers = action.payload;
        },
        setCurrentEvent: (state, action) => {
            state.currentEvent = action.payload;
        },
        setEventActions: (state, action) => {
            state.eventActions = action.payload;
        },
        setAllActions: (state, action) => {
            state.allActions = action.payload;
        },
        setEventEvidences: (state, action) => {
            state.eventEvidences = action.payload;
        },
        setCurrentEventManagementTab: (state, action) => {
            state.currentEventManagementTab = action.payload;
        },
        setCurrentInvestigationReportSection: (state, action) => {
            state.currentInvestigationReportSection = action.payload;
        }
    }
});

export const {
    setAllEvents,
    setEventUsers,
    setEventActions,
    setAllActions,
    setCurrentEvent,
    setEventEvidences,
    setCurrentEventManagementTab,
    setCurrentInvestigationReportSection
} = eventManagerSlice.actions;

const EventManagerReducer: Reducer<EventManagerState> = eventManagerSlice.reducer;

export default EventManagerReducer;
