import * as React from 'react';
import './style.scss';
import InfoIcon from '../BEIcons/InfoIcon';
import { PrimaryTheme } from '../../Config/Theme/theames';

export interface IBEInfoBoxProps {
  title?: any;
  description: any;
  viewMoreDescription?: any;
  icon?: React.ReactNode;
  backgroundColor?: string;
}

export function BEInfoBox(props: IBEInfoBoxProps) {
  const [fullDetails, setFullDetails] = React.useState<boolean>(false);
  return (
    <div className='info-box' style={props.backgroundColor ? { backgroundColor: props.backgroundColor } : {}}>
      <div>{props.icon ? props.icon : <InfoIcon fill={PrimaryTheme.primaryGray} /> }</div>
      <div style={props.icon? {display:'flex', flexDirection:'column', justifyContent:'center'}:{}}>
        <p>
        {props?.title && <strong>{props.title}</strong>}</p>
        <p>
          {props.description}
          {
            fullDetails &&
            <>
              {props.viewMoreDescription}
              <br />
            </>
          }
          {
            props.viewMoreDescription &&
            <>
            <br />
              <span className='read-more'
                onClick={() => setFullDetails(!fullDetails)}
              >
                Read {fullDetails ? 'less' : 'more'}
              </span>
            </>
           
          }
        </p>
      </div>
    </div>
  );
}
