import * as React from 'react';
import { BETabs } from '../../../../../Components/BETabs';
import './style.scss';
import { EventList } from '../../Components/EventList';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { getAllActions, getAllEvents, getEventUsers } from '../../Actions';
import { getAllUsers } from '../../../../../views/Actions/UsersManagementActions';
import { getBusinessUnits } from '../../../../../views/Actions/BusinessUnitActions';
import { setCurrentEventManagementTab } from '../../../../../Redux/EventManagerReducer';

export interface IEventManagementProps {
}

const EventManagementTabs = [
    {
        key: 'My tasks',
        label: 'My tasks',
        stage: [1, 3, 5, 6]
    },
    {
        key: 'New events',
        label: 'New events',
        stage: [0]
    },
    {
        key: 'Closed',
        label: 'Closed',
        stage: [2, 4]
    }
];

export function EventManagement(props: IEventManagementProps) {
    const dispatch = useAppDispatch();
    const currentEventManagementTab = useTypedSelector((state) => state.eventManager.currentEventManagementTab);
    const eventUsers = useTypedSelector((state) => state.eventManager.eventUsers);
    const userProfile = useTypedSelector((state) => state.user.userProfile);
    const allEvents = useTypedSelector((state) => state.eventManager.allEvents);
    const allActions = useTypedSelector(state => state.eventManager.allActions);
    const allUsers = useTypedSelector(state => state.userMgmt);
    const businessUnits = useTypedSelector((state) => state.entity.BusinessUnits);

    React.useEffect(() => {
        const user = userProfile?.data?.user;
        if (eventUsers.status === 'idle') {
            dispatch(getEventUsers());
        }
        if (allEvents.status === 'idle' && user) {
            dispatch(getAllEvents(user));
        }
        if (allActions.status === 'idle') {
            dispatch(getAllActions());
        }
        if(allUsers.status === 'idle') {
            dispatch(getAllUsers());
        }
        if (businessUnits?.status == "idle"){
             dispatch(getBusinessUnits())
        };
    }, [userProfile]);

    return (
        <div className='event-management'>
            <BETabs
                destroyInactiveTabPane
                className='event-management-tabs'
                size='large'
                type='card'
                activeKey={currentEventManagementTab}
                tabBarGutter={20}
                tabBarStyle={{ marginLeft: 40, marginTop: '2rem' }}
                onChange={(key) => {
                    dispatch(setCurrentEventManagementTab(key));
                }}

                items={
                    EventManagementTabs.map((tab) => {
                        return {
                            key: tab.key,
                            label: tab.label,
                            children: <EventList stage={tab.stage} />
                        }
                    })
                }
            />
           
        </div>
    );
}
