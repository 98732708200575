import * as React from 'react';
import './style.scss';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { checkTakeAction, patchEvent } from '../../Actions';
import { ESGEvent } from '../../../../../Redux/Types/eventManagerTypes';
import { InvestigationReport } from '../InvestigationReport';
import { BEEventListItem } from '../../../../../Components/BEEventManagement/BEEventListItem';
import BEStatusTag from '../../../../../Components/BEStatusTag';
import { setCurrentEvent, setCurrentInvestigationReportSection } from '../../../../../Redux/EventManagerReducer';
import { AddEventDrawer } from '../Drawers/AddEventDrawer';
import { AssignInvestigationDrawer } from '../Drawers/AssignInvestigationDrawer';
import { AddInvestigationReportDrawer } from '../Drawers/AddInvestigationReportDrawer';
import { InvestigationDetailsDrawer } from '../Drawers/InvestigationDetailsDrawer';
import { EventInfoModal } from '../Drawers/EventInfoModal';
import { EVENT_OWNER, EVENT_STAGE_CHOICES, LEAD_INVESTIGATOR, SeverityOptions } from '../../../../../data/eventManager';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import EmptyEmissions from '../../../../../assets/images/OnboardingImages/EmptyDashboard.png'
import { GlobalLoader } from '../../../../../Components/GlobalLoader';
import BEMultipleSelect from '../../../../../Components/BEMultipleSelect';
import { InfoCircleOutlined } from '@ant-design/icons';

export interface IEventListProps {
    stage: number[];
}

export const demoFilterData = [
    {
        name: 'All',
        value: 0
    },
    {
        name: 'Pending',
        value: 1
    },
    {
        name: 'Completed',
        value: 2
    }
];
export const EventSeverityMapping: Record<number, { title: string, status: 'warning' | 'error' | 'success' }> = {
    1: {
        title: '1',
        status: 'error'
    },
    2: {
        title: '2',
        status: 'warning'
    },
    3: {
        title: '3',
        status: 'warning'
    },
    4: {
        title: '4',
        status: 'error'
    },
    5: {
        title: '5',
        status: 'error'
    },


};
export function EventList(props: IEventListProps) {
    const allEvents = useTypedSelector(state => state.eventManager.allEvents);
    const allActions = useTypedSelector(state => state.eventManager.allActions);
    const eventUsers = useTypedSelector((state) => state.eventManager.eventUsers);
    const currentEvent = useTypedSelector(state => state.eventManager.currentEvent);
    const [events, setEvents] = React.useState<ESGEvent[] | null | undefined>([]);
    const [openInvestigationReport, setOpenInvestigationReport] = React.useState(false);
    const [openAddInvestigationReportDrawer, setOpenAddInvestigationReportDrawer] = React.useState(false);
    const [openAssignInvestigationDrawer, setOpenAssignInvestigationDrawer] = React.useState(false);
    const [openAddEventDrawer, setOpenAddEventDrawer] = React.useState(false);
    const [openInvestigationDetailsDrawer, setOpenInvestigationDetailsDrawer] = React.useState(false);
    const [openInfoModal, setOpenInfoModal] = React.useState(false);
    const currentInvestigationReportSection = useTypedSelector(state => state.eventManager.currentInvestigationReportSection);
    const [filters, setFilters] = React.useState<{ name: keyof ESGEvent, isActive: boolean, status: number | undefined }[]>([
        {
            name: 'actual_severity',
            isActive: false,
            status: undefined
        },
        {
            name: 'actual_impact',
            isActive: false,
            status: undefined
        },
        {
            name: 'stage',
            isActive: false,
            status: undefined
        }
    ]);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (allEvents.status === 'success') {
            setEvents(allEvents?.data?.filter((event: ESGEvent) => props.stage.includes(event.stage)));
        }
    }, [allEvents]);

    React.useEffect(() => {
        const filteredEvents = filterEvents(allEvents?.data || [], filters);
        setEvents(filteredEvents);
    }, [filters, allEvents]);

    const filterEvents = (events: ESGEvent[], filter: { name: keyof ESGEvent, isActive: boolean, status: number | undefined }[]) => {
        const activeFilters = filter.filter((filter) => filter.isActive);
        return events.filter(event =>
            activeFilters.every(({ name, status }) => event[name] === status) && props.stage.includes(event.stage)
        );
    }

    const updateStatus = (name: keyof ESGEvent, status: number) => {
        setFilters(prev =>
            prev.map(f =>
                f.name === name
                    ? { ...f, status, isActive: Boolean(status) } // Enable filter if status > 0, disable if status = 0
                    : f
            )
        );
    };

    const resetAllFilters = () => {
        setFilters([
            {
                name: 'actual_severity',
                isActive: false,
                status: undefined
            },
            {
                name: 'actual_impact',
                isActive: false,
                status: undefined
            },
            {
                name: 'stage',
                isActive: false,
                status: undefined
            }
        ]);
    }

    return (
        <div className='event-list'>
            <div className='event-list-header'>
                <div className='event-list-header-items'>
                    <BEMultipleSelect
                        single
                        noForm
                        placeholder='Severity level'
                        name='Severity level'
                        data={SeverityOptions.map((severity) => ({ name: severity.label, value: severity.value }))}
                        valueFeild='value'
                        labelFeild='name'
                        value={filters.find((filter) => filter.name === 'actual_severity')?.status}
                        onChange={(status: any) => {
                            updateStatus('actual_severity', status);
                        }}
                        size='middle'
                    />
                    <BEMultipleSelect
                        single
                        noForm
                        placeholder='Impact level'
                        name='Impact level'
                        data={SeverityOptions.map((severity) => ({ name: severity.label, value: severity.value }))}
                        valueFeild='value'
                        labelFeild='name'
                        value={filters.find((filter) => filter.name === 'actual_impact')?.status || undefined}
                        onChange={(status: any) => {
                            updateStatus('actual_impact', status);
                        }}
                        size='middle'
                    />
                    {!props.stage.includes(0) && <BEMultipleSelect
                        single
                        noForm
                        placeholder='Event Status'
                        name='Impact level'
                        data={EVENT_STAGE_CHOICES.filter((stage) => props.stage.includes(stage.value)).map((stage) => ({ name: stage.label, value: stage.value }))}
                        valueFeild='value'
                        labelFeild='name'
                        value={filters.find((filter) => filter.name === 'stage')?.status}
                        onChange={(status: any) => {
                            updateStatus('stage', status);
                        }}
                        size='middle'
                    />}

                    <BEButton
                        className='secondary-green'
                        size='middle'
                        onClick={resetAllFilters}
                    >
                        Clear Filters
                    </BEButton>
                    {/* <BEButton
                        className='primary'
                        size='large'
                        onClick={() => {
                            setOpenInvestigationReport(true);
                        }}
                    >
                        Create new event
                    </BEButton> */}
                </div>
            </div>
            {allEvents.status === 'loading' ?
                <GlobalLoader />
                : events?.length === 0 ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '50vh' }}>
                        <img height="150px" width="150px" src={EmptyEmissions} alt="empty-event" />
                        <p style={{ marginTop: '1rem' }}>{(props.stage.includes(2) || props.stage.includes(4)) ? 'No task has been assigned to you' : (!props.stage.includes(1) || !props.stage.includes(3)) ? 'Click create new event button to add a new event' : 'No task has been assigned to you'}</p>
                    </div> :
                    <div className='event-list-body'>
                        {
                            events?.sort(
                                (a, b) => a.timestamp < b.timestamp ? 1 : -1
                            ).map((event, index) => {
                                return (
                                    <BEEventListItem
                                        title={event.title}
                                        index={index}
                                        styles={(event.stage === 6 && event.event_user_role) === LEAD_INVESTIGATOR ? 
                                                { backgroundColor: PrimaryTheme.primaryRedErrorLight, border: `1px dashed ${PrimaryTheme.primaryRedErrorBorder}` } 
                                                :
                                                allActions.data?.some((action) => action.stage === 3 && action.event === event.id) && event.event_user_role === LEAD_INVESTIGATOR ?
                                                { backgroundColor: '#FAFAFA', border: `1px dashed ${PrimaryTheme.primaryRedErrorBorder}` }
                                                : {}
                                            }
                                        listItemRightComponent={
                                            <div style={{ display: 'flex', gap: '1rem' }}>
                                                {
                                                    event.event_user_role === LEAD_INVESTIGATOR &&
                                                        checkTakeAction(event?.id, allActions?.data || []) &&
                                                        event?.stage === 5
                                                        ?
                                                        <>
                                                            <BEButton
                                                                className='primary'
                                                                size='small'
                                                                onClick={() => {
                                                                    dispatch(setCurrentEvent(event));
                                                                    dispatch(setCurrentInvestigationReportSection('Actions'))
                                                                    setOpenInvestigationReport(true);
                                                                }}
                                                            >
                                                                Take action
                                                            </BEButton>
                                                        </>
                                                        :
                                                        event.event_user_role === LEAD_INVESTIGATOR ?
                                                            <>
                                                                {event.stage === 6 &&
                                                                    <BEButton
                                                                        className='secondary-green'
                                                                        size='small'
                                                                        onClick={() => {
                                                                            dispatch(setCurrentEvent(event));
                                                                            dispatch(setCurrentInvestigationReportSection('Investigation'))
                                                                            setOpenInvestigationReport(true);
                                                                        }}
                                                                    >
                                                                        Re-Investigate
                                                                    </BEButton>
                                                                }
                                                                {(Number(event?.stage) !== 4 && event?.stage !== 6) && <BEButton
                                                                    className='secondary-green'
                                                                    size='small'
                                                                    onClick={() => {
                                                                        dispatch(setCurrentEvent(event));
                                                                        dispatch(setCurrentInvestigationReportSection('Investigation'));
                                                                        setOpenInvestigationReport(true);
                                                                    }}
                                                                >
                                                                    Investigate
                                                                </BEButton>}
                                                                {
                                                                    event.stage === 4 &&
                                                                    <BEButton
                                                                        className='secondary-green'
                                                                        size='small'
                                                                        onClick={() => {
                                                                            dispatch(setCurrentEvent(event));
                                                                            dispatch(setCurrentInvestigationReportSection('Actions'))
                                                                            setOpenInvestigationReport(true);
                                                                        }}
                                                                    >
                                                                        View
                                                                    </BEButton>
                                                                }
                                                            </>
                                                            :
                                                            event.event_user_role === EVENT_OWNER &&
                                                                event.stage === 0 ?
                                                                <BEButton
                                                                    className='secondary-green'
                                                                    size='small'
                                                                    onClick={() => {
                                                                        dispatch(setCurrentEvent(event));
                                                                        // setOpenInvestigationReport(true);
                                                                        setOpenInfoModal(true);
                                                                    }}
                                                                >
                                                                    Validate
                                                                </BEButton>
                                                                :
                                                                event.stage === 1 ?
                                                                    <>
                                                                        <BEButton
                                                                            className='secondary'
                                                                            size='small'
                                                                            onClick={() => {
                                                                                if (event) {
                                                                                    dispatch(patchEvent(event?.id, { stage: 2 }))
                                                                                }
                                                                            }}
                                                                        >
                                                                            Close
                                                                        </BEButton>
                                                                        <BEButton
                                                                            className='primary'
                                                                            size='small'
                                                                            onClick={() => {
                                                                                dispatch(setCurrentEvent(event));
                                                                                setCurrentInvestigationReportSection('Investigation');
                                                                                setOpenAssignInvestigationDrawer(true);
                                                                            }}
                                                                        >
                                                                            Investigate
                                                                        </BEButton>
                                                                    </>
                                                                    :
                                                                    [5, 6].includes(event.stage) &&
                                                                        allActions.data?.some((action) => action.event === event.id)
                                                                        ?
                                                                        <BEButton
                                                                            className='secondary-green'
                                                                            size='small'
                                                                            onClick={() => {
                                                                                dispatch(setCurrentEvent(event));
                                                                                dispatch(setCurrentInvestigationReportSection('Actions'))
                                                                                setOpenInvestigationReport(true);
                                                                            }}
                                                                        >
                                                                            Review Actions
                                                                        </BEButton>
                                                                        :
                                                                        [3, 5, 6].includes(event.stage) ?
                                                                            <BEButton
                                                                                className='secondary-green'
                                                                                size='small'
                                                                                onClick={() => {
                                                                                    dispatch(setCurrentEvent(event));
                                                                                    dispatch(setCurrentInvestigationReportSection('Investigation'))
                                                                                    setOpenInvestigationReport(true);
                                                                                }}
                                                                            >
                                                                                Review Investigation
                                                                            </BEButton> :
                                                                            event.stage === 4 ?
                                                                                <BEButton
                                                                                    className='primary'
                                                                                    size='small'
                                                                                    onClick={() => {
                                                                                        dispatch(setCurrentEvent(event));
                                                                                        dispatch(setCurrentInvestigationReportSection('Actions'))
                                                                                        setOpenInvestigationReport(true);

                                                                                    }}
                                                                                >
                                                                                    View
                                                                                </BEButton>
                                                                                :
                                                                                null
                                                }
                                            </div>
                                        }
                                        listItemBottomComponent={<>
                                            {event.actual_severity &&
                                                <BEStatusTag status={EventSeverityMapping[event.actual_severity]?.status} style={(event.actual_severity === 1 || event.actual_severity === 2) ? { color: PrimaryTheme.primaryVividBlue, backgroundColor: PrimaryTheme.primaryVividBlueLight } : undefined}>
                                                    Level {event.actual_severity}
                                                </BEStatusTag>
                                            }
                                            <BEStatusTag status='warning' style={{ color: PrimaryTheme.primaryGoldenBrown, backgroundColor: PrimaryTheme.primaryGoldenBrownLight }}  >
                                                Reporter: {
                                                    eventUsers?.data?.find(user => user.id === event.reported_by)?.first_name + ' ' +
                                                    eventUsers?.data?.find(user => user.id === event.reported_by)?.last_name
                                                }
                                            </BEStatusTag>
                                            
                                        </>}
                                        listItemBottomComponent2={
                                            <>
                                            {
                                                event.event_user_role === LEAD_INVESTIGATOR && allActions.data?.some((action) => action.event === event.id && action.stage === 3) &&
                                                <div style={{padding:'0.6rem', color:PrimaryTheme.primaryRedError}}>
                                                    <p style={{margin:'0.6rem'}}> <InfoCircleOutlined/> Note - actions got rejected , kindly review and complete it </p>
                                                </div>
                                            }
                                            </>
                                        }
                                    />
                                )
                            })
                        }
                    </div>
            }

            <InvestigationReport
                open={openInvestigationReport}
                setOpen={setOpenInvestigationReport}
                heading={currentEvent?.title || ''}
            />
            <AssignInvestigationDrawer
                open={openAssignInvestigationDrawer}
                setOpen={setOpenAssignInvestigationDrawer}
            />
            <AddEventDrawer
                open={openAddEventDrawer}
                setOpen={setOpenAddEventDrawer}
            />
            <AddInvestigationReportDrawer
                open={openAddInvestigationReportDrawer}
                setOpen={setOpenAddInvestigationReportDrawer}
            />
            {/* <InvestigationDetailsDrawer
                open={openInvestigationDetailsDrawer}
                setOpen={setOpenInvestigationDetailsDrawer}
            /> */}
            <EventInfoModal
                open={openInfoModal}
                onCancel={() => setOpenInfoModal(false)}
            />
        </div>
    );
}
