import API from "../../API";

export const GET_ALL_EVENTS = async (user_id: number) => {
    return await API.GET(`/events/events/${user_id}/`);
}

export const GET_EVENT_USERS = async () => {
    return await API.GET("/events/event-users/");
}

export const GET_EVENT_ACTIONS = async (currentEventId: number) => {
    return await API.GET(`/events/event-actions/${currentEventId}/`);
}

export const GET_ALL_ACTIONS = async () => {
    return await API.GET("/events/event-actions/");
}

export const GET_EVENT_EVIDENCES = async (currentEventId: number) => {
    return await API.GET(`/events/event-evidences/${currentEventId}/`);
}

export const POST_EVENT_USER = async (data: any) => {
    return await API.POST("/events/event-users/", data);
}

export const POST_EVENT = async (data: any, secret: string) => {
    return await API.POST('/events/punch-event/?secret=' + secret, data);
}

export const PUNCH_EVENT_EVIDENCES = async (event_id: number, data: any[], secret: string) => {
    return await API.PATCH(`/events/punch-event/${event_id}/?secret=` + secret, data, true);
}

export const POST_EVENT_ACTION = async (data: any) => {
    return await API.POST('/events/event-actions/', data);
}

export const PATCH_EVENT_ACTION = async (id: number, data: any) => {
    return await API.PATCH(`/events/event-actions/${id}/`, data);
}

export const POST_EVENT_EVIDENCE = async (data: any) => {
    return await API.POST('/events/event-evidences/', data, true);
}

export const DELETE_EVENT_EVIDENCE = async (id: number) => {
    return await API.DELETE(`/events/event-evidences/${id}/`);
}

export const PATCH_EVENT = async (id: number, data: any) => {
    return await API.PATCH(`/events/events/${id}/`, data, true);
}

export const DELETE_EVENT_ACTION = async (id: number) => {
    return await API.DELETE(`/events/event-actions/${id}/`);
}