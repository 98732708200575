import * as React from 'react';
import { BEEventListItem } from '../../../../../../../Components/BEEventManagement/BEEventListItem';
import { BEButton } from '../../../../../../../Components/BEFormItems/BEButton';
import BEStatusTag from '../../../../../../../Components/BEStatusTag';
import { useTypedSelector } from '../../../../../../../Config/Hooks/useTypedSelector';
import { AddActionEvidenceDrawer } from '../../../Drawers/AddActionEvidenceDrawer';
import { EventAction } from '../../../../../../../Redux/Types/eventManagerTypes';
import { EVENT_OWNER, LEAD_INVESTIGATOR } from '../../../../../../../data/eventManager';
import EntityManager from '../../../../../../../Components/BEIcons/EntityManager';
import { PrimaryTheme } from '../../../../../../../Config/Theme/theames';
import { AddActionDrawer } from '../../../Drawers/AddActionDrawer';
import './styles.scss';
import { BEInfoBox } from '../../../../../../../Components/BEInfoBox';
import BulbInfoIcon from '../../../../../../../Components/BEIcons/BulbInfoIcon';

export interface IEventActionsProps {
}

export const EventActionStageMapping: Record<number, { title: string, status: 'warning' | 'error' | 'success' }> = {
  0: {
    title: 'Pending',
    status: 'error'
  },
  1: {
    title: 'Filled',
    status: 'warning'
  },
  2: {
    title: 'Approved',
    status: 'success'
  },
  3: {
    title: 'Rejected',
    status: 'error'
  }
}

export function EventActions(props: IEventActionsProps) {
  const eventActions = useTypedSelector(state => state.eventManager.eventActions);
  const eventUsers = useTypedSelector(state => state.eventManager.eventUsers);
  const currentEvent = useTypedSelector(state => state.eventManager.currentEvent);
  const [addActionEvidenceDrawer, setAddActionEvidenceDrawer] = React.useState(false);
  const [currentEventAction, setCurrentEventAction] = React.useState<EventAction>();
  const [openAddActionDrawer, setOpenAddActionDrawer] = React.useState(false);

  const roleActionButtons: any = {
    [LEAD_INVESTIGATOR]: (action: EventAction) => (
      <>
        {(action.stage === 0 || action.stage === 1 || action.stage === 3) ? <BEButton disabled={currentEvent?.stage !== 5 && (action.stage == 0 || action.stage === 3)} className='secondary-green' size='small' onClick={() => {
          setCurrentEventAction(action);
          setAddActionEvidenceDrawer(true);
        }}>
          {action.stage === 0 ? 'Take action' : action.stage === 1 ? 'View' : action.stage === 3 ? 'Take action' : null}
        </BEButton>
          : null
        }
      </>
    )
    ,
    [EVENT_OWNER]: (action: EventAction) => (
      <>
        {action.stage === 1 ? <BEButton className={'secondary-green'} size='small' onClick={() => {
          setCurrentEventAction(action);
          setAddActionEvidenceDrawer(true);
        }}>
          Review
        </BEButton> :
          null
        }
      </>
    )
  };

  return (
    <div style={{ padding: '1rem' }}>

      {
        currentEvent?.event_user_role === LEAD_INVESTIGATOR &&
        <>
          <div style={{ marginBottom: '1rem' }}>
            <BEInfoBox
              description='The action takers will be notified once the investigation reports are approved by event owner'
              icon={<BulbInfoIcon />}
              backgroundColor='#FFFAEC'
            />
          </div>
          {eventActions.data?.length === 0 ?
            <>
              <div className='event-actions-empty'>
                <div className='circle'>
                  <EntityManager stroke={PrimaryTheme.primaryGreen} />
                </div>
                <p>Add actions and assign people to it </p>
                <BEButton size='large' className='primary' onClick={() => setOpenAddActionDrawer(true)}>+ Add action</BEButton>
              </div>
            </> :
            <>
              {currentEvent.stage !== 4 &&
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
                  <BEButton className='primary' onClick={() => { setOpenAddActionDrawer(true); setCurrentEventAction(undefined); }}>+ Add action</BEButton>
                </div>
              }
            </>}
        </>
      }
      {eventActions?.data?.map((action, index) => (
        <BEEventListItem
          title={action.title}
          index={index}
          listItemRightComponent={
            <>
              {roleActionButtons[currentEvent?.event_user_role as string](action)}
            </>
          }
          listItemBottomComponent={<>
            <BEStatusTag status='success' style={{ color: PrimaryTheme.primaryGoldenBrown, backgroundColor: PrimaryTheme.primaryGoldenBrownLight }}>
              Deadline: {action.deadline}
            </BEStatusTag>
            <BEStatusTag status='success' style={{ color: PrimaryTheme.primaryVividBlue, backgroundColor: PrimaryTheme.primaryVividBlueLight }}>
              Assigned to: {eventUsers?.data?.filter((user) => user.id == action.action_owner).map((user) => user.first_name + ' ' + user.last_name)}
            </BEStatusTag>
            <BEStatusTag status={EventActionStageMapping[action.stage].status}>
              Status: {EventActionStageMapping[action.stage].title}
            </BEStatusTag>
          </>
          }

        />
      ))}
      <AddActionEvidenceDrawer
        open={addActionEvidenceDrawer}
        setOpen={setAddActionEvidenceDrawer}
        action={currentEventAction}
      />
      <AddActionDrawer
        open={openAddActionDrawer}
        setOpen={setOpenAddActionDrawer}
        action={currentEventAction}
      />
    </div>
  );
}
