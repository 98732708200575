import * as React from 'react';
import { BEButton } from '../../../../../../Components/BEFormItems/BEButton';
import { BEDrawer } from '../../../../../../Components/BEDrawer';
import { Form, Select } from 'antd';
import { LableRequired } from '../../../../../../Components/BEFormItems/LableRequired';
import { BEInput } from '../../../../../../Components/BEFormItems/BEInput';
import BEDatePicker from '../../../../../../Components/BEDatePicker';
import { EventAction } from '../../../../../../Redux/Types/eventManagerTypes';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { deleteEventAction, postEventAction } from '../../../Actions';
import { TeamForm } from '../AssignInvestigationDrawer/TeamForm';
import dayjs from 'dayjs';
import { PrimaryTheme } from '../../../../../../Config/Theme/theames';

interface IAddActionDrawerProps {
	open: boolean;
	setOpen: Function;
	action?: EventAction | null;
}

export function AddActionDrawer(props: IAddActionDrawerProps) {
	const [form] = Form.useForm();
	const dispatch = useAppDispatch();
	const eventUsers = useTypedSelector((state) => state.eventManager.eventUsers);
	const currentEvent = useTypedSelector((state) => state.eventManager.currentEvent);
	const [loading, setLoading] = React.useState(false);
	const [assignEventUserId, setAssignEventUserId] = React.useState<number | null>(null);
	const [eventUsersTableData, setEventUsersTableData] = React.useState<any[]>([]);

	React.useEffect(() => {
		if (props.action) {
			form.setFieldsValue({
				title: props.action.title,
				deadline: props.action.deadline
			})
			setAssignEventUserId(props.action.action_owner)
		} else {
			form.resetFields()
		}
	}, [props.open])

	React.useEffect(() => {
		const assignedActionOwner = eventUsers?.data?.find((user: any) => user.id === assignEventUserId);
		if (assignedActionOwner) {
			setEventUsersTableData([assignedActionOwner]);
		}
	}, [eventUsers, assignEventUserId])

	const deadlineValidator = (rule: any, value: any) => {
		if (value < dayjs()) {
			return Promise.reject('Deadline should be greater than today');
		}
		return Promise.resolve();
	}

	const handleFinish = async (values: any) => {
		if (currentEvent && assignEventUserId) {
			const data = {
				title: values.title,
				action_owner: assignEventUserId,
				deadline: values.deadline.format('YYYY-MM-DD'),
				stage: 0,
				event: currentEvent.id,
				assigned_by: currentEvent.lead_investigator
			}
			await dispatch(postEventAction(data)).then(() => {
				setAssignEventUserId(null);
				setEventUsersTableData([]);
			});
			props.setOpen(false);
		}
	}

	return (
		<BEDrawer
			heading="Action"
			width='500px'
			open={props.open}
			setOpen={props.setOpen}
			footer={
				<>
					{!props.action ?
						<BEButton size='large' className='primary' onClick={() => { form.submit() }}>
							Add Action
						</BEButton>
						:
						<BEButton size='large' className='primary' onClick={() => { dispatch(deleteEventAction(props.action?.id as number)); props.setOpen(false) }}>
							Delete Action
						</BEButton>}
					<BEButton size='large' className='secondary' onClick={() => props.setOpen(false)}>
						Cancel
					</BEButton>
				</>
			}
		>
			<Form form={form} onFinish={handleFinish}>
				<LableRequired>
					Title
				</LableRequired>
				<Form.Item name={'title'} rules={[{ required: true, message: 'Enter title' }]}>
					<BEInput placeholder='Enter title' />
				</Form.Item>
				<LableRequired>
					Assign
				</LableRequired>
				<Form.Item>
					<Select
						onChange={(value: any) => setAssignEventUserId(value)}
						value={assignEventUserId}
						placeholder='Select a user'
						style={{ width: '100%' }}
					>
						{/* currentEvent?.investigation_team is string of ids: 1,2,3,4
						make it in an array */}
						{eventUsers?.data?.filter((user: any) => 
							currentEvent?.investigation_team?.split(',')?.includes(user.id.toString())
						).map((user: any) => {
							return <Select.Option
								key={user.id}
								value={user.id}
							>{user.first_name} {user.last_name}
							</Select.Option>
						})}
					</Select>
				</Form.Item>
				<p style={{ width: '100%', textAlign: 'center'}}>or</p>
				<TeamForm
					isActionAssigned
					tableData={eventUsersTableData}
					setEventUserId={setAssignEventUserId}
				/>
				<LableRequired>
					Deadline
				</LableRequired>
				<Form.Item name={'deadline'}
					rules={
						[
							{ required: true, message: 'Select a deadline' },
							{ validator: deadlineValidator }
						]
					}
				>
					<BEDatePicker
						form={form}
						format='YYYY-MM-DD'
						field='deadline'
					/>
				</Form.Item>
			</Form>
		</BEDrawer>
	);
}
