import * as React from 'react';
import './styles.scss'
interface IBEEventSummaryPanelProps {
	eventSummary: {
		title: string,
		value: string | React.ReactNode,
	}[];
	width?: string;
}

export function BEEventSummaryPanel (props: IBEEventSummaryPanelProps) {
	const [groupedEventSummary, setGroupedEventSummary] = React.useState<any[]>([]);

	const chunkItem = (array:any, chunkSize:number) => {
		let result = [];
		for (let i = 0; i < array.length; i += chunkSize) {
			  result.push(array.slice(i, i + chunkSize));
		}
		return result;
	};

	React.useEffect(() => {
		const groupedEvents = chunkItem(props.eventSummary, 3);
		setGroupedEventSummary(groupedEvents);
	}, [props.eventSummary]);


	return (
	  <div className='event-summary' style={{ width: props.width }}>
			{groupedEventSummary.map((group, index) => (
				<div className='event-summary-group' key={index}>
					{group.map((item:{title:string, value:string}, index:number) => (
						<div className='event-summary-item' key={index}>
							{item.title}: {item.value}
						</div>
					))}
				</div>
			))}
	  </div>
	);
}
