import * as React from 'react';
import { BEDrawer } from '../../../../../../Components/BEDrawer';
import { Checkbox, Form, Select } from 'antd';
import { LableRequired } from '../../../../../../Components/BEFormItems/LableRequired';
import BEDatePicker from '../../../../../../Components/BEDatePicker';
import { BEButton } from '../../../../../../Components/BEFormItems/BEButton';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { patchEvent } from '../../../Actions';
import { TeamForm } from './TeamForm';
import { BEMessage } from '../../../../../../Components/BEMessage';
import { setCurrentEvent } from '../../../../../../Redux/EventManagerReducer';
import dayjs from 'dayjs';

interface IAssignInvestigationDrawerProps {
	open: boolean;
	setOpen: Function;
}

export function AssignInvestigationDrawer(props: IAssignInvestigationDrawerProps) {
	const dispatch = useAppDispatch();
	const [form] = Form.useForm();
	const allEvents = useTypedSelector(state => state.eventManager.allEvents);
	const currentEvent = useTypedSelector(state => state.eventManager.currentEvent);
	const users = useTypedSelector(state => state.userMgmt);
	const [loading, setLoading] = React.useState(false);
	const [isInvestigateWithReviewer, setIsInvestigateWithReviewer] = React.useState(false);
	const [investigationTeam, setInvestigationTeam] = React.useState<any[]>([]);
	const eventUsers = useTypedSelector(state => state.eventManager.eventUsers);

	React.useEffect(() => {
		if (currentEvent) {
			const newCurrentEvent = allEvents?.data?.find((event: any) => event.id === currentEvent.id);
			if (newCurrentEvent) {
				dispatch(setCurrentEvent(newCurrentEvent));
			}
		}
	}, [allEvents]);

	React.useEffect(() => {
		const investigationTeam = currentEvent?.investigation_team?.split(',');
		if (investigationTeam && investigationTeam?.length > 0) {
			const team = eventUsers?.data?.filter((user: any) => investigationTeam?.includes(String(user.id)));
			if (team) {
				setInvestigationTeam(team);
			}
		} else {
			setInvestigationTeam([]);
		}
	}, [currentEvent, eventUsers]);

	React.useEffect(() => {
		form.resetFields();
	}, [props.open]);

	const deadlineValidator = (rule: any, value: any) => {
		if (value < dayjs()) {
			return Promise.reject('Deadline should be greater than today');
		}
		return Promise.resolve();
	}

	const handleFormSubmit = async (values: any) => {
		if (investigationTeam.length === 0) {
			BEMessage.error('Please add at least one member to the investigation team');
			return;
		}
		if (currentEvent) {
			setLoading(true);
			await dispatch(patchEvent(currentEvent?.id, {
				lead_investigator: values.investigationLead,
				deadline: values.deadline.format('YYYY-MM-DD'),
				stage: 3,
			}));
			setLoading(false);
			props.setOpen(false);
		}
	}

	const handleClose = () => {
		if (currentEvent) {
			dispatch(patchEvent(currentEvent?.id, {
				stage: 4,
			}));
		}
	}

	return (
		<BEDrawer
			heading={'Assign Investigation'}
			width='600px'
			open={props.open}
			setOpen={props.setOpen}
			footer={
				<>
					<BEButton
						className='primary'
						loading={loading}
						onClick={() => {
							form.submit();
						}}
					>
						Assign Investigation
					</BEButton>
					{/* <BEButton onClick={handleClose}>
						Cancel
					</BEButton> */}
				</>
			}
		>
			<Form form={form} onFinish={handleFormSubmit}>
				<LableRequired>
					Assign investigation lead
				</LableRequired>
				<Form.Item name={'investigationLead'} rules={[{ required: true, message: 'Please select investigation lead' }]}>
					<Select placeholder='Select investigation lead'>
						{
							users?.data?.filter(
								user => user.id !== currentEvent?.event_owner
							).map(user => (
								<Select.Option key={user.id} value={user.id}>
									{user.first_name} {user.last_name} ({user.email})
								</Select.Option>
							))
						}
					</Select>
				</Form.Item>
				<LableRequired>
					Deadline
				</LableRequired>
				<Form.Item name={'deadline'}
					rules={
						[
							{ required: true, message: 'Please select deadline', },
							{ validator: deadlineValidator }
						]
					}
				>
					<BEDatePicker
						handleDateChange={(value: any) =>
							form.setFieldsValue({ deadline: value })}
					/>
				</Form.Item>
				<LableRequired>
					Investigation Team
				</LableRequired>
				<TeamForm
					tableData={investigationTeam}
				/>
				<br />
				{/* <Form.Item name={'investigationTeam'}>
					<Checkbox
						checked={isInvestigateWithReviewer}
						onChange={(e) => setIsInvestigateWithReviewer(e.target.checked)}
					>
						Investigate with reviewer
					</Checkbox>
				</Form.Item>
				{isInvestigateWithReviewer &&
					<>
						<p>
							Assign Reviewer
						</p>
						<Form.Item>
							<Select placeholder='Select the person who will review the report'>
							</Select>
						</Form.Item>
					</>
				} */}
			</Form>
		</BEDrawer>
	);
}
