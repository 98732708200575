import * as React from 'react';
import { BEDrawer } from '../../../Components/BEDrawer';
import { BEEvidenceList } from '../../../Components/BEEventManagement/BEEvidenceList';
import { Form, Select, TimePicker } from 'antd';
import { LableRequired } from '../../../Components/BEFormItems/LableRequired';
import { BEInput } from '../../../Components/BEFormItems/BEInput';
import { BETextArea } from '../../../Components/BEFormItems/BETextArea';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import BEDatePicker from '../../../Components/BEDatePicker';
import { BEButton } from '../../../Components/BEFormItems/BEButton';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { punchEvent, postEventUser, punchEventEvidences } from '../../../Features/EventManager/EventManager/Actions';
import { useSearchParams } from 'react-router-dom';
import { BEMessage } from '../../../Components/BEMessage';
import BEConfirmModal from '../../../Components/BEConfirmModal';
import { EVENT_TYPE_CHOICES, EVENT_GROUP_CHOICES, SeverityOptions } from '../../../data/eventManager';
import dayjs from 'dayjs';

interface IPunchEventDrawerProps {
}

export function PunchEventDrawer(props: IPunchEventDrawerProps) {
	const [form] = Form.useForm();
	const [file, setFile] = React.useState<any>(null);
	const [eviList, setEviList] = React.useState<string[]>([]);
	const [evidenceList, setEvidenceList] = React.useState<string[]>([]);

	const dispatch = useAppDispatch();
	const [searchParams] = useSearchParams();
	const [loading, setLoading] = React.useState(false);
	const [eventPunched, setEventPunched] = React.useState(false);

	const handleAddEvent = async (values: any) => {
		const date = values.eventDate.format('YYYY-MM-DD');
		let time: any = null;
		if (values.eventTime) {
			time = values.eventTime.format('HH:mm:ss');
		}
		let bu_id: any = searchParams.get('bu_id');
		let org_id: any = searchParams.get('org_id');
		bu_id = bu_id ? parseInt(bu_id) : null;
		org_id = org_id ? parseInt(org_id) : null;

		if (evidenceList?.length === 0) {
			BEMessage.error('Please upload at least evidence');
			return;
		}

		if (!bu_id || !org_id) {
			BEMessage.error('Please scan the QR code again');
			return;
		}

		const eventUserData = {
			email: values.email,
			first_name: values.firstName,
			last_name: values.lastName,
			org: org_id,
			event_role: "EVENT_REPORTER"
		}
		setLoading(true);
		await dispatch(postEventUser(eventUserData, false)).then(async (res) => {
			console.log(res);
			const eventData = {
				org: org_id,
				business_unit: bu_id,
				reported_by: res?.data?.id,
				title: values.eventTitle,
				date: date,
				time: time,
				actual_severity: values.actualSeverity,
				potential_severity: values.potentialSeverity,
				actual_impact: values.actualImpact,
				potential_impact: values.potentialImpact,
				event_type: values.eventType,
				event_group: values.eventCategory,
				description: values.eventSummary,
				specific_location: values.specificArea,
				immediate_action: values.immediateAction,
				immediate_reason: values.immediateReasons
			}
			await dispatch(punchEvent(eventData, res?.data?.secret))
				.then(async (res1: any) => {
					const eventId = res1.data.id;
					Promise.all(evidenceList.map(async (file: any) =>
						await dispatch(punchEventEvidences(eventId, { file: file }, res?.data?.secret)))).then(() => {
							setEventPunched(true);
						}).catch((err) => {
							console.log(err);
							BEMessage.error('Something went wrong');
							setLoading(false);
						});
				}).catch((err) => {
					console.log(err);
					BEMessage.error('Something went wrong');
					setLoading(false);
				});
		}).catch((err) => {
			console.log(err);
			BEMessage.error('Something went wrong');
			setLoading(false);
		});
		setLoading(false);
	}

	const onTimeChange = (time: any) => {
		form.setFieldsValue({ eventTime: time });
	}

	React.useEffect(() => {
		const currentDate = dayjs();
		form.setFieldsValue({
			eventDate: currentDate
		});
	}, []);
	React.useEffect(() => {
		if (file) {
			setEvidenceList([...evidenceList, file]);
			setEviList([...eviList, file?.name]);
		}
	}, [file]);

	return (
		// <BEDrawer
		// 	heading='Punch Event'
		// 	isMobile
		// 	open={true}
		// 	setOpen={() => { }}
		// 	closeIcon={true}
		// 	width='100%'
		// 	footer={null}
		// >
		<>
			<div style={{ margin: '1rem' }}>
				<h2 style={{ marginBottom: '1rem' }}>Punch Event</h2>
				<div>
					<LableRequired>
						Upload evidences
					</LableRequired>
					<BEEvidenceList
						uploadEvidence
						evidenceList={eviList}
						evidenceFiles={evidenceList}
						setEvidenceList={setEvidenceList}
						setFile={setFile}
						disableOnClick
					/>
				</div>
				<br />
				<Form form={form} style={{ color: PrimaryTheme.primaryGray }} onFinish={handleAddEvent}>
					<LableRequired>
						Event Title
					</LableRequired>
					<Form.Item name={'eventTitle'} rules={[{ required: true, message: 'Please enter event title' }]}>
						<BEInput size='large' />
					</Form.Item>
					<LableRequired>
						First Name
					</LableRequired>
					<Form.Item name={'firstName'} rules={[{ required: true, message: 'Please enter first name' }]}>
						<BEInput size='large' />
					</Form.Item>
					<LableRequired>
						Last Name
					</LableRequired>
					<Form.Item name={'lastName'} rules={[{ required: true, message: 'Please enter last name' }]}>
						<BEInput size='large' />
					</Form.Item>
					<LableRequired>
						Email
					</LableRequired>
					<Form.Item name={'email'} rules={[{ required: true, type: 'email', message: 'Please enter email' }]}>
						<BEInput size='large' />
					</Form.Item>
					<LableRequired>
						Specific area
					</LableRequired>
					<Form.Item name={'specificArea'} rules={[{ required: true, message: 'Please enter specific area' }]}>
						<BEInput size='large' />
					</Form.Item>
					<LableRequired>
						Event date
					</LableRequired>
					<Form.Item name={'eventDate'} rules={[{ required: true, message: 'Please enter event date' }]}>
						<BEDatePicker
							form={form}
							format='DD/MM/YYYY'
							field='eventDate'
						/>
					</Form.Item>
					<p>
						Event time
					</p>
					<Form.Item name={'eventTime'}>
						<TimePicker
							style={{ width: '100%' }}
							onChange={(time) => onTimeChange(time)}
							size='large'
							format={'HH:mm:ss'}
						/>
					</Form.Item>
					<LableRequired>
						Event summary
					</LableRequired>
					<Form.Item name={'eventSummary'} rules={[{ required: true, message: 'Please enter event summary' }]}>
						<BETextArea
							count={{
								show: true,
								max: 500
							}}
							form={form}
							rows={8}
							placeholder=''
							maxLength={500}
						/>
					</Form.Item>
					<LableRequired>
						Event type
					</LableRequired>
					<Form.Item name={'eventType'} rules={[{ required: true, message: 'Please select event type' }]}>
						<Select>
							{EVENT_TYPE_CHOICES.map(option => (
								<Select.Option key={option.value} value={option.value}>
									{option.label}
								</Select.Option>
							))
							}
						</Select>
					</Form.Item>
					<LableRequired>
						Event category
					</LableRequired>
					<Form.Item name={'eventCategory'} rules={[{ required: true, message: 'Please select event category' }]}>
						<Select>
							{EVENT_GROUP_CHOICES.map(option => (
								<Select.Option key={option.value} value={option.value}>
									{option.label}
								</Select.Option>
							))
							}
						</Select>
					</Form.Item>
					<p>
						Actual severity
					</p>
					<Form.Item name={'actualSeverity'}>
						<Select>
							{SeverityOptions.map(option => (
								<Select.Option key={option.value} value={option.value}>
									{option.label}
								</Select.Option>
							))
							}
						</Select>
					</Form.Item>
					<p>
						Potential severity
					</p>
					<Form.Item name={'potentialSeverity'}>
						<Select>
							{SeverityOptions.map(option => (
								<Select.Option key={option.value} value={option.value}>
									{option.label}
								</Select.Option>
							))
							}
						</Select>
					</Form.Item>
					<p>
						Actual Impact
					</p>
					<Form.Item name={'actualImpact'}>
						<Select>
							{SeverityOptions.map(option => (
								<Select.Option key={option.value} value={option.value}>
									{option.label}
								</Select.Option>
							))
							}
						</Select>
					</Form.Item>
					<p>
						Potential Impact
					</p>
					<Form.Item name={'potentialImpact'}>
						<Select>
							{SeverityOptions.map(option => (
								<Select.Option key={option.value} value={option.value}>
									{option.label}
								</Select.Option>
							))
							}
						</Select>
					</Form.Item>
					<p>
						Immediate Action
					</p>
					<Form.Item name={'immediateAction'}>
						<BEInput size='large' />
					</Form.Item>
					<p>
						Immediate findings/reasons
					</p>
					<Form.Item name={'immediateReasons'}>
						<BEInput size='large' />
					</Form.Item>
				</Form>
				<BEButton loading={loading} className='primary' size='large' onClick={() => form.submit()}>
					Add event
				</BEButton>
			</div>
			<BEConfirmModal
				title={'Event registered successfully'}
				message={'Event has been registered successfully. Please close this tab and scan the QR code again if you want to add more events.'}
				visible={eventPunched}
				setVisible={setEventPunched}
				type='success'
				onConfirm={() => { window.close() }}
				hideButtons
			/>
		</>
		// </BEDrawer>
	);
}
