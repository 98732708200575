import { DatePicker, ConfigProvider } from "antd";
import React, { useEffect } from "react";
import { Dayjs } from "dayjs";
import "./styles.scss";
import { PrimaryTheme } from "../../Config/Theme/theames";
import dayjs from "dayjs";

interface DatePickerProps {
  handleDateChange?: Function;
  picker?: any;
  form?: any;
  field?: string;
  value?: any;
  format?: string;
  disabled?: boolean;
  placeholder?: string;
  acceptNullData?: boolean;
  showTime?: boolean;
}

const BEDatePicker = (props: DatePickerProps) => {
  const style: React.CSSProperties = {
    borderRadius: "50%",
    border: "none",
  };
  const cellRender = React.useCallback((current: number | Dayjs | string, info: any) => {
    if (info.type !== "date") {
      return info.originNode;
    }
    if (typeof current === "number") {
      return <div className="ant-picker-cell-inner">{current}</div>;
    }
    if(typeof current === 'string'){
      current = dayjs(current)
    }
    return (
      <div className="ant-picker-cell-inner" style={style}>
        {current.date()}
      </div>
    );
  }, []);

  if (props.value !== undefined && props.value !== 'nan')
    return (
      <ConfigProvider
        theme={{
          components: {
            DatePicker: {
              cellHoverBg: PrimaryTheme.primaryGreenLight,
              activeBorderColor: "rgba(0, 0, 0, 0.04)",
              hoverBorderColor: "rgba(0, 0, 0, 0.04)",
              cellHeight: 23,
              cellWidth: 35,
            },
          },
        }}
      >
        <DatePicker
          showTime={props.showTime}
          placeholder={props.placeholder}
          style={{ width: "100%" }}
          cellRender={cellRender}
          defaultValue={props.value ? dayjs(props.value) : null}
          value={props.value ? dayjs(props.value) : null}
          format={
            props.format ? props.format : props.picker ? "" : "DD/MM/YYYY"
          }
          onChange={(date:any) => {
            if (props.acceptNullData && !date) {
              if (props.form && props.field) {
                props.form.setFieldValue(props.field, date);
                return;
              } else props.handleDateChange!(date);
            }
            if (!date) return;
            if (props.form && props.field) {
              props.form.setFieldValue(props.field, date);
              return;
            } else props.handleDateChange!(date);
          }}
          picker={props.picker ? props.picker : "date"}
          disabled={props.disabled}
          size="large"
        />
      </ConfigProvider>
    );

  return (
    <ConfigProvider
      theme={{
        components: {
          DatePicker: {
            cellHoverBg: PrimaryTheme.primaryGreenLight,
            activeBorderColor: "rgba(0, 0, 0, 0.04)",
            hoverBorderColor: "rgba(0, 0, 0, 0.04)",
            cellHeight: 23,
            cellWidth: 35,
          },
        },
      }}
    >
      <DatePicker
        placeholder={props.placeholder}
        style={{ width: "100%" }}
        cellRender={cellRender}
        showToday={false}
        format={props.format ? props.format : props.picker ? "" : "DD/MM/YYYY"}
        onChange={(date:any) => {
          if (props.form && props.field) {
            props.form.setFieldValue(props.field, date);
            return;
          } else props.handleDateChange!(date);
        }}
        picker={props.picker ? props.picker : 'date'}
        disabled={props.disabled}
        size="large"
      />
    </ConfigProvider>
  );
};

export default BEDatePicker;
