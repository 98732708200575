import React, { useRef, useState } from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from "antd";
import DeleteIcon from "../../../Components/BEIcons/DeleteIcon";
import { PrimaryTheme } from "../../../Config/Theme/theames";
import UploadedIcon from "../../../Components/BEIcons/UploadedIcon";
import UploadIcon from "../../../Components/BEIcons/UploadIcon";

interface FileUploadMobileProps {
  text?: string; 
  hint?: string;
  handleFileChange: Function;
  required?: boolean;
  fileType?: string | string[];
  fileSize?: number;
  loading?: boolean;
  refreshUpload?: boolean;
  maxImageWidth?: number;
  imageQuality?: number;
}

const FileUploadMobile = (props: FileUploadMobileProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploaded, setUploaded] = useState(false);
  const [success, setSuccess] = useState(true);
  const [message, setMessage] = useState("");
  const [fileName, setFileName] = useState("");
  const [compressing, setCompressing] = useState(false);

  const compressImage = async (file: File, maxWidth: number, quality: number): Promise<File> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target?.result as string;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          if (width > maxWidth) {
            height = (height * maxWidth) / width;
            width = maxWidth;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx?.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              if (blob) {
                const compressedFile = new File([blob], file.name, {
                  type: file.type,
                  lastModified: Date.now(),
                });
                resolve(compressedFile);
              } else {
                reject(new Error('Canvas to Blob conversion failed'));
              }
            },
            file.type,
            quality
          );
        };
        img.onerror = reject;
      };
      reader.onerror = reject;
    });
  };

  const handleCompression = async (file: File): Promise<File> => {
    if (!file || !file.type.startsWith('image/')) return file;

    try {
      setCompressing(true);
      const compressedFile = await compressImage(
        file,
        props.maxImageWidth || 1920,
        props.imageQuality || 0.8
      );
      setCompressing(false);
      return compressedFile;
    } catch (error) {
      console.error('Image compression failed:', error);
      setCompressing(false);
      return file;
    }
  };

  const validateFile = (file: File) => {
    if (!file) return false;

    if (props.fileType === "image" && !(file.type === "image/jpeg" || file.type === "image/png")) {
      setMessage("Only .png and .jpeg files allowed");
      setSuccess(false);
      return false;
    }

    if (props.fileType === "file" && file.type !== "application/pdf") {
      setMessage("Only PDF files allowed");
      setSuccess(false);
      return false;
    }

    if (props.fileType === "excel" && file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      setMessage("Only Excel (.xlsx) files allowed");
      setSuccess(false);
      return false;
    }

    if (typeof props.fileType !== 'string') {
      const fileTypeCheck = props.fileType?.find((item: string) => item === file.type);
      if (!fileTypeCheck) {
        setMessage("Invalid file type");
        setSuccess(false);
        return false;
      }
    }

    if (props.fileSize && file.size > props.fileSize) {
      setMessage(`File must be less than ${props.fileSize / 1000000} MB`);
      setSuccess(false);
      return false;
    }

    return true;
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleFileChange(null);
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      try {
        const compressedFile = await handleCompression(file);
        if (!validateFile(compressedFile)) return;
        props.handleFileChange(compressedFile);
        setUploaded(true);
        setSuccess(true);
        setFileName(compressedFile.name);
      } catch (error) {
        console.error('File processing failed:', error);
        setMessage("File processing failed");
        setSuccess(false);
      }
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDelete = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    props.handleFileChange(null);
    setUploaded(false);
    setSuccess(true);
  };

  React.useEffect(() => {
    setUploaded(false);
    setMessage("");
    setSuccess(true);
    setFileName("");
  }, [props.refreshUpload]);

  return (
    <div className="mobile-upload-container">
      {props.loading ? (
        <div className="mobile-loading">
          <Spin indicator={<LoadingOutlined style={{ fontSize: '2rem', color: PrimaryTheme.primaryGreenDark }} spin />} />
          <p className="mobile-loading-text">Uploading...</p>
        </div>
      ) : (
        <div className={`mobile-upload-box ${!success ? 'mobile-upload-error' : ''}`} onClick={handleClick}>
          {/* {uploaded && (
            <div className="mobile-delete-btn" onClick={handleDelete}>
              <DeleteIcon stroke="red" inheritSize />
            </div>
          )} */}
          <input type="file" ref={fileInputRef} onChange={handleFileChange} className="mobile-file-input" />
          <div className="mobile-upload-content">
            <div className="mobile-upload-icon">
              {/* {uploaded ? <UploadedIcon stroke={PrimaryTheme.primaryGreenDark} inheritSize /> : */}
              <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'2rem', marginBottom:'1rem'}}>
                <UploadIcon stroke={PrimaryTheme.primaryGreenDark} inheritSize />
              </div>
              <p style={{textAlign:'center',color:PrimaryTheme.primaryGreenDark}}>Upload evidences</p>
              {/* } */}
            </div>
            {/* <p className="mobile-upload-text">{uploaded ? `Uploaded: ${fileName}` : `${props.text}${props.required ? ' *' : ''}`}</p>
            <p className={`mobile-upload-hint ${!success ? 'mobile-upload-error-hint' : ''}`}>{success ? props.hint : message}</p> */}
            {/* {!success && <div className="mobile-browse-btn" onClick={(e) => { e.stopPropagation(); fileInputRef.current?.click(); }}>Browse File</div>} */}
          </div>
        </div>
      )}
    </div>
  );
};

FileUploadMobile.defaultProps = {
  text: "Tap to upload file",
  hint: "Upload image (.png, .jpg) under 2 MB",
  required: true,
  fileType: "image",
  fileSize: 2000000,
};

export default FileUploadMobile;