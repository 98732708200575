import * as React from 'react';
import { BEDrawer } from '../../../../../../Components/BEDrawer';
import { Form, Select } from 'antd';
import { LableRequired } from '../../../../../../Components/BEFormItems/LableRequired';
import { BEInput } from '../../../../../../Components/BEFormItems/BEInput';
import FileUpload from '../../../../../../Components/BEFileUpload/FileUpload';
import BEMultipleSelect from '../../../../../../Components/BEMultipleSelect';
import BEDatePicker from '../../../../../../Components/BEDatePicker';
import { BETextArea } from '../../../../../../Components/BEFormItems/BETextArea';
import { BEButton } from '../../../../../../Components/BEFormItems/BEButton';
import './styles.scss';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { patchEvent, postEventEvidence } from '../../../Actions';
import { BEMessage } from '../../../../../../Components/BEMessage';
import { setLoading } from '../../../../../../Redux/ReportingReducer';
import { ESGEvent } from '../../../../../../Redux/Types/eventManagerTypes';
import { EVENT_TYPE_CHOICES, EVENT_GROUP_CHOICES, SeverityOptions } from '../../../../../../data/eventManager';
interface IAddEventDrawerProps {
	open: boolean;
	setOpen: Function;
}

export function AddEventDrawer(props: IAddEventDrawerProps) {
	const [form] = Form.useForm();
	const [file, setFile] = React.useState(null);
	const currentEvent = useTypedSelector(state => state.eventManager.currentEvent);
	const businessUnits = useTypedSelector(state => state.entity.BusinessUnits);
	const dispatch = useAppDispatch();

	const onHandleSave = (values: any) => {
		if (currentEvent) {
			const fieldsToUpdate = [
				{ field: 'title', key: 'eventTitle' },
				{ field: 'date', key: 'eventDate' },
				{ field: 'description', key: 'eventSummary' },
				{ field: 'event_type', key: 'eventType' },
				{ field: 'specific_location', key: 'specificArea' },
				{ field: 'actual_severity', key: 'actualSeverity' },
				{ field: 'potential_severity', key: 'potentialSeverity' },
				{ field: 'event_group', key: 'eventCategory' },
				{ field: 'potential_impact', key: 'potentialImpact' },
				{ field: 'actual_impact', key: 'actualImpact' },
				{ field: 'immediate_action', key: 'immediateAction' },
				{ field: 'immediate_reason', key: 'immediateReason' }
			];
			const updatedData: Partial<ESGEvent> = {};

			fieldsToUpdate.forEach(field => {
				if (values[field.key] !== currentEvent[field.field as keyof ESGEvent]) {
					if (field.field === 'date') {
						updatedData[field.field as keyof ESGEvent] = values[field.key].format('YYYY-MM-DD');
					} else {
						if (!(values[field.key] === undefined) && !(values[field.key] === null)) {
							updatedData[field.field as keyof ESGEvent] = values[field.key];
						}
					}
				}
			}
			);
			setLoading(true);
			if (file) {
				dispatch(postEventEvidence(
					{
						file: file,
						event: currentEvent.id
					}
				)).then(() => {
					BEMessage.success('Evidence uploaded successfully');
				}).catch(() => {
					BEMessage.error('Failed to upload evidence');
				})
			}
			if (Object.keys(updatedData).length > 0) {
				dispatch(patchEvent(currentEvent?.id,
					updatedData
				)).then((status) => {
					if (status) {
						BEMessage.success('Event updated successfully');
					} else {
						BEMessage.error('Failed to update event');
					}
				})
			}
		}
		setLoading(false);
		props.setOpen(false);
	}

	React.useEffect(() => {
		currentEvent && form.setFieldsValue({
			eventTitle: currentEvent.title,
			location: businessUnits.data.find(bu => Number(bu.id) === (currentEvent.business_unit))?.name,
			eventDate: currentEvent.date,
			eventSummary: currentEvent.description,
			eventType: currentEvent.event_type,
			specificArea: currentEvent.specific_location,
			eventCategory: currentEvent.event_group,
			potentialImpact: currentEvent.potential_impact,
			actualImpact: currentEvent.actual_impact,
			immediateAction: currentEvent.immediate_action,
			immediateReason: currentEvent.immediate_reason ? currentEvent.immediate_reason : '',
		});
	}, [props.open]);

	return (
		<BEDrawer
			heading="Edit Event"
			open={props.open}
			setOpen={props.setOpen}
			width='100%'
			footer={
				<>
					<BEButton size='large' className='primary' onClick={() => form.submit()}>
						Save
					</BEButton>
					<BEButton size='large' className='secondary' onClick={() => props.setOpen(false)}>
						Cancel
					</BEButton>
				</>
			}
		>
			<Form form={form} className='add-event-form' onFinish={onHandleSave}>
				<div className='group'>
					<div className='form-input' >
						<LableRequired>
							Event Title
						</LableRequired>
						<Form.Item name={'eventTitle'}>
							<BEInput size='large' />
						</Form.Item>
						<LableRequired>
							Location
						</LableRequired>
						<Form.Item name={'location'}>
							<Select size='large' disabled>
							</Select>
						</Form.Item>
					</div>
					<div className='form-input' >
						<p>Upload Evidence</p>
						<FileUpload
							fileType='pdf'
							height='55px'
							width='97%'
							text='Upload Evidence'
							hint='PDF or .ZIP, Max 3MB'
							handleFileChange={setFile}
							refreshUpload={props.open}
						/>
					</div>
				</div>
				<div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
					<div className='form-input' >
						<LableRequired>Specific Area</LableRequired>
						<Form.Item name={'specificArea'}>
							<BEInput size='large' />
						</Form.Item>
					</div>
					<div className='form-input' >
						<LableRequired>
							Event Date
						</LableRequired>
						<Form.Item name={'eventDate'}>
							<BEDatePicker
								format='DD/MM/YYYY'
								field='eventDate'
								form={form}
							/>
						</Form.Item>
					</div>
				</div>
				<div>
					<p>Event Summary</p>
					<Form.Item name={'eventSummary'}>
						<BETextArea
							count={{
								show: true,
								max: 500
							}}
							name='eventSummary'
							form={form}
							rows={8}
							placeholder=''
							maxLength={500}
						/>
					</Form.Item>
				</div>
				<div className='group'>
					<div className='form-input' >
						<LableRequired>
							Event Type
						</LableRequired>
						<Form.Item name={'eventType'}>
							<Select size='large' >
								{EVENT_TYPE_CHOICES.map((option) => (
									<Select.Option value={option.value}>{option.label}</Select.Option>
								))}
							</Select>
						</Form.Item>
					</div>
					<div className='form-input' >
						<LableRequired>
							Event Category
						</LableRequired>
						<Form.Item name={'eventCategory'}>
							<Select size='large'>
								{EVENT_GROUP_CHOICES.map((option) => (
									<Select.Option value={option.value}>{option.label}</Select.Option>
								))}
							</Select>
						</Form.Item>
					</div>
				</div>
				<div className='group'>
					<div className='form-input' >
						<p>
							Actual Severity
						</p>
						<Form.Item name={'actualSeverity'}>
							<Select size='large'>
								{SeverityOptions.map((option) => (
									<Select.Option value={option.value}>{option.label}</Select.Option>
								))}
							</Select>
						</Form.Item>
					</div>
					<div className='form-input' >
						<p>
							Potential severity
						</p>
						<Form.Item name={'potentialSeverity'}>
							<Select size='large'>
								{SeverityOptions.map((option) => (
									<Select.Option value={option.value}>
										{option.label}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</div>
				</div>
				<div className='group'>
					<div className='form-input' >
						<p>
							Potential impact
						</p>
						<Form.Item name={'potentialImpact'}>
							<Select size='large'>
								{SeverityOptions.map((option) => (
									<Select.Option value={option.value}>{option.label}</Select.Option>
								))}
							</Select>
						</Form.Item>
					</div>
					<div className='form-input' >
						<p>
							Actual Impact
						</p>
						<Form.Item name={'actualImpact'}>
							<Select size='large'>
								{SeverityOptions.map((option) => (
									<Select.Option value={option.value}>{option.label}</Select.Option>
								))}
							</Select>
						</Form.Item>
					</div>
				</div>
				<div className='group'>
					<div className='form-input' >
						<p>
							Immediate Action
						</p>
						<Form.Item name={'immediateAction'}>
							<BEInput size='large' />
						</Form.Item>
					</div>
					<div className='form-input'>
						<p>
							Immediate findings/reasons
						</p>
						<Form.Item name={'immediateReason'}>
							<BEInput size='large' />
						</Form.Item>
					</div>
				</div>
			</Form>
		</BEDrawer>
	);
}
