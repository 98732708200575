import * as React from 'react';
import './styles.scss';
interface IBEEventListItemProps {
	title: string;
	index: number;
	listItemRightComponent: React.ReactNode;
	listItemBottomComponent: React.ReactNode;
	listItemBottomComponent2?: React.ReactNode;
	styles?: React.CSSProperties;
}

export function BEEventListItem(props: IBEEventListItemProps) {
	return (
		<div style={{ marginBottom: '1rem' }}>
			<div className='event-list-item' style={props.styles}>
				<div className='row-1'>
					<h3 className='title'>{props.index + 1}. {props.title}</h3>
					{props.listItemRightComponent}
				</div>
				<div className='row-2'>
					{props.listItemBottomComponent}
				</div>
				{props.listItemBottomComponent2 && <div className='event-list-item-bottom'>
				{props.listItemBottomComponent2}
			</div>}
			</div>
			
		</div>
	);
}

