import React, { useEffect, useState } from "react";
import { PrimaryTheme } from "../../Config/Theme/theames";
import "./styles.scss";
import { color } from "highcharts";

interface TrackerStatusCard {
  status: 'info' | 'success' | 'warning' | 'error';
  children: React.ReactNode;
  size?: 'small' | 'large';
  style?: React.CSSProperties;
}

const warningStyles = {
  color: PrimaryTheme.amber,
  backgroundColor: "rgba(240, 153, 72, 0.2)",
};

const successStyles = {
  color: PrimaryTheme.primaryGreen,
  backgroundColor: "rgba(46, 152, 68, 0.2)",
};

const errorStyles = {
  color: PrimaryTheme.primaryRedError,
  backgroundColor: "rgba(240, 79, 109, 0.2)",
};

const infoStyles = {
  color: PrimaryTheme.primaryGray,
  backgroundColor: 'white',
  border: `1px solid ${PrimaryTheme.primaryGreyLight}`,
};

const styleMap = {
  'warning': warningStyles,
  'success': successStyles,
  'error': errorStyles,
  'info': infoStyles,
}

interface TrackerStatusPoint {
  status: 'success' | 'warning' | 'error' | 'info';
  children: React.ReactNode;
  childrenColored?: boolean;
  style?: React.CSSProperties;
  size?: 'small' | 'large';
}

export const BEStatusPoint = (props: TrackerStatusPoint) => {
  return (
      <div className="tracker-status-point" >
      <div className="point"
        style={{ backgroundColor: props?.status === 'success' ? PrimaryTheme.primaryGreen : props?.status === 'warning' ? PrimaryTheme.amber : PrimaryTheme.primaryRedError }}
      >

      </div>
      <p
        style={{ color: props.childrenColored ? props?.status === 'success' ? PrimaryTheme.primaryGreen : props?.status === 'warning' ? PrimaryTheme.amber : PrimaryTheme.primaryRedError : 'black', fontSize: props.childrenColored ? '10px' : '1rem', ...props.style }}
      >
        {props.children}
      </p>
      </div>
  )
}


const BEStatusTag = (props: TrackerStatusCard) => {
  const [styles, setStyles] = useState({});
  useEffect(() => {
    setStyles(styleMap[props?.status] );
  }, [props?.status]);
  return (
    <div 
    className={'tracker-status' + (props.size === 'large' ? ' large' : '')}
     style={!props.style ? styles : props.style}>
      {props.children}
    </div>
  );
};

export default BEStatusTag;

